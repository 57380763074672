<app-nav></app-nav>
<div id="page-content-wrapper ">
    <div class="container-fluid">

        <div class="container">
            <br>
            <div class="card border-primary p-2">
                <div class="card-title">
                    <h4 class="card-title d-print-none">Panel de reportes</h4>
                </div>
                <div class="card-body">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="form-group">
    <label for="">Seleccione un/a {{entity}}</label>
    <select (ngModelChange)="onChange($event)" required name="estado" [(ngModel)]="item" [compareWith]="compareFn" class="form-control form-sm" required>
        <option selected="true" selected> Seleccione un/a {{entity}}</option>
        <option *ngFor="let d of items" [ngValue]="d">{{d.nombre}}</option>
    </select>

    <small id="helpId" class="text-muted">
        {{ entity }}
    </small>
</div>
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Venta, Mercaderia, DetalleVenta } from 'src/app/model/index.model';
import { ActivatedRoute, Router } from '@angular/router';
import { VentaService, DetalleVentaService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-frm-venta',
  templateUrl: './frm-venta.component.html',
  styleUrls: ['./frm-venta.component.css']
})
export class FrmVentaComponent implements OnInit {
  /*
   * Varaiables de control para comportamientos del componente
  */


  mercaderia: Mercaderia;
  detalle: DetalleVenta[];

  cargainicial: DetalleVenta[];
  clientes

  @Output()
  finalizado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output()
  cancelado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  /*
   * control de operaciones a realizar
   */
  proccess: Boolean = false;

  public entity = 'ventas';
  public item: Venta;
  private id: Number;
  total: Number = 0

  constructor(private route: ActivatedRoute, private router: Router, private wsdl: VentaService, private wsdldp: DetalleVentaService) {
    this.item = new Venta();
    this.detalle = UturuncoUtils.getSession("detalle") ? JSON.parse(UturuncoUtils.getSession("detalle")) : [];
    this.cargainicial = this.detalle;
    this.calcularT();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.findID();
    this.listCliente();

  }

  async findID() {
    try {
      if (this.id > 0) {
        let data = await this.wsdl.doFind(this.id).then();
        let res = JSON.parse(JSON.stringify(data));
        if (res.code == 200) {
          this.item = res.data;
          /* encontrar los detalles para mostrar */
          let detda = await this.wsdldp.getCriteria(this.id, 1, 1000).then();
          let detres = JSON.parse(JSON.stringify(detda));
          if (detres.code == 200) {
            this.detalle = detres.data.docs;
          } else {
            this.detalle = [];
          }

          this.cargainicial = this.detalle;
          this.calcularT();


        }
      }

    } catch (error) {
      UturuncoUtils.showToas("Error inesperado", "error");
    }

  }


  async listCliente() {
    try {

      this.proccess = true;
      let data = await this.wsdldp.getCliente().then();

      const result = JSON.parse(JSON.stringify(data));

      if (result.code == 200) {
        this.clientes = result.data;
        this.item.cliente = this.clientes[0].cliente == "" ? "CONSUMIDOR FINAL" : this.clientes[0].cliente;

      } else if (result.code === 666) {
        // logout app o refresh token

      } else {
        this.clientes = [];
        UturuncoUtils.showToas(result.msg, "error");
        this.item.cliente = "CONSUMIDOR FINAL";
      }

    } catch (error) {
      this.item.cliente = "CONSUMIDOR FINAL";

    } finally {
      this.proccess = false;

    }

  }

  doAction(f: NgForm) {
    /* validar */
    this.item.usuario = JSON.parse(UturuncoUtils.getSession("user"));

    if (this.detalle.length == 0) {
      UturuncoUtils.showToas("Debe ingresar al menos un producto al carrito", "warning");
      return;
    }


    if (f.valid) {
      if (this.id > 0) {
        //editar
        this.doEdit();
      } else {
        this.doCreate();
      }
    } else {
      alert('Validar');
    }
  }

  async doEdit() {
    try {

      this.proccess = true;
      let arra = this.detalle.map(e => "[" + e.cantidad + "] " + e.mercaderia.nombre)
      this.item.descripcion = arra.toString();

      const res = await this.wsdl.doUpdate(this.item, this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        for (let index = 0; index < this.detalle.length; index++) {
          const det = this.detalle[index];
          det.venta = this.item;
          if (det.id == undefined) {
            let rr = await this.wsdldp.doInsert(det);
            console.log(rr)
          }
        }

        UturuncoUtils.showToas("Se actualizado correctemte", "success");
        this.cargainicial = [];
        this.item = new Venta();
        this.item.cliente = this.clientes[0].cliente
        this.detalle = [];
        UturuncoUtils.setSession("detalle", JSON.stringify(this.detalle));
        this.calcularT();

        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
        this.back();

      } else {
        UturuncoUtils.showToas(result.msg, "error");
        this.back();

      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
      this.back();
    } finally {
      this.proccess = false;

    }
  }

  async doCreate() {
    try {

      this.proccess = true;
      let arra = this.detalle.map(e => "[" + e.cantidad + "] " + e.mercaderia.nombre)
      this.item.descripcion = arra.toString();
      const res = await this.wsdl.doInsert(this.item).then();
      this.proccess = false;
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        this.item = result.data;
        for (let index = 0; index < this.detalle.length; index++) {
          const det = this.detalle[index];
          det.venta = this.item;
          await this.wsdldp.doInsert(det);
        }
        UturuncoUtils.showToas("Se creo correctemte", "success");
        this.cargainicial = [];
        this.item = new Venta();
        this.detalle = [];
        UturuncoUtils.setSession("detalle", JSON.stringify(this.detalle));
        this.calcularT();
        this.item.cliente = this.clientes[0].cliente

        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
        this.back();

      } else {
        UturuncoUtils.showToas(result.msg, "error");
        this.back();
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
      this.back();
    } finally {
      this.proccess = false;
    }
  }

  back() {
    this.router.navigateByUrl("admin/" + this.entity.toLowerCase() + "/lst");
  }

  venta(event) {
    this.detalle = event;
    UturuncoUtils.setSession("detalle", JSON.stringify(event));
    this.calcularT();
  }

  calcularT() {
    this.total = 0;
    let cantidades = [];
    cantidades = this.detalle.map(a => (parseFloat("" + a.cantidad) * parseFloat("" + a.precio)));

    if (cantidades.length > 0) {
      this.total += cantidades.reduce((ant, act) => {
        return parseInt("" + ant) + parseInt("" + act);
      })
    }

  }


  getRR() {
    return this.proccess;
  }

}

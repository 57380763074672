import { Component, OnInit, Output, EventEmitter, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Mercaderia, Grupo } from 'src/app/model/index.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MercaderiaService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-frm-mercaderia',
  templateUrl: './frm-mercaderia.component.html',
  styleUrls: ['./frm-mercaderia.component.css']
})
export class FrmMercaderiaComponent implements OnInit {
  /*
    * Varaiables de control para comportamientos del componente
    */
  @Output()
  finalizado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output()
  cancelado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @ViewChild("serie", { static: false }) serie: ElementRef;
  /*
   * control de operaciones a realizar
   */
  proccess: Boolean;

  blocker: boolean = false

  public entity = 'mercaderias';
  public item: Mercaderia;
  private id: Number;

  constructor(private route: ActivatedRoute, private router: Router, private wsdl: MercaderiaService) {
    this.item = new Mercaderia();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.findID();
  }

  async findID() {
    try {
      if (this.id > 0) {
        let data = await this.wsdl.doFind(this.id).then();
        let res = JSON.parse(JSON.stringify(data));
        if (res.code == 200) {
          this.item = res.data;
        }
      } else {
        this.item = new Mercaderia();
      }

    } catch (error) {
      UturuncoUtils.showToas("Error inesperado", "error");
    }

  }
  doAction(f: NgForm) {
    /* validar */

    if (f.valid) {
      if (this.id > 0) {
        //editar
        this.doEdit();
      } else {
        this.doCreate();
      }
    } else {
      alert('Validar');
    }
  }

  async changeCodbar() {
    try {
      if (this.id != 0) {
        this.blocker = false;
      } else {

        this.proccess = true;
        const res = await this.wsdl.doFindCode(this.item.serie).then();
        const result = JSON.parse(JSON.stringify(res));

        if (result.code == 200 && result.data) {
          this.item = result.data
          this.blocker = true;
          this.serie.nativeElement.select()
          UturuncoUtils.showToas("Este código ya existe", "warning");
        } else if (result.code == 666) {


        } else {
          let sr = this.item.serie;
          this.item = new Mercaderia()
          this.item.serie = sr;
          this.blocker = false;
        }

      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    } finally {
      this.proccess = false;

    }
  }

  async doEdit() {
    try {

      this.proccess = true;
      const res = await this.wsdl.doUpdate(this.item, this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        UturuncoUtils.showToas("Se actualizado correctemte", "success");
        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    this.proccess = false;
  }

  async  doCreate() {
    try {

      this.proccess = true;
      const res = await this.wsdl.doInsert(this.item).then();
      console.log(res)
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        // this.item = result.data;
        UturuncoUtils.showToas("Se creo correctemte", "success");
        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
  }

  back() {
    this.router.navigateByUrl("admin/" + this.entity.toLowerCase() + "/lst/");
  }
  itemSel(event: Grupo) {
    if (event.id !== undefined) {
      this.item.grupo = event;
    }
  }
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { CajaService } from 'src/app/service/index.service';
import { Caja } from 'src/app/model/index.model';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { FilCajaComponent } from '../../filtro/fil-caja/fil-caja.component';

@Component({
  selector: 'app-reporte-caja',
  templateUrl: './reporte-caja.component.html',
  styleUrls: ['./reporte-caja.component.css']
})
export class ReporteCajaComponent implements OnInit {
  @ViewChild(FilCajaComponent, { static: true }) fil: FilCajaComponent;
  entity = "Cajas"

  items: Caja[];
  item: Caja;
  fecha = new Date();
  constructor(private wsdl: CajaService) {
    this.items = []
  }

  ngOnInit() {

    this.fil.list();

  }

  select(item: Caja) {
    this.item = item;
  }

  doFound(event: Caja[]) {
    this.items = event;
  }

  cancel() {
    this.item = new Caja();
    this.fil.list();
  }

  async setResultCancel(event: boolean) {
    UturuncoUtils.showToas("Operación cancelada", "info");
  }

  setResult(event: any) {
    this.cancel();
  }

  // para exportar a excel
  exportTableToExcel(tableID, filename = '') {
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';

    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    // Specify file name
    filename = filename ? filename + '.xlsx' : 'excel_data.xlsx';

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(['ufeff', tableHTML], {
        type: dataType
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }

}

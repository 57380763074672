import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DetalleVenta } from 'src/app/model/index.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DetalleVentaService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import Swal from 'sweetalert2';
import { FilDetalleVentaComponent } from '../../filtro/fil-detalle-entrega/fil-detalle-entrega.component';

@Component({
  selector: 'app-lst-detalle-entrega',
  templateUrl: './lst-detalle-entrega.component.html',
  styleUrls: ['./lst-detalle-entrega.component.css']
})
export class LstDetalleVentaComponent implements OnInit {
  @ViewChild("close", { static: false }) close: ElementRef;


  @ViewChild(FilDetalleVentaComponent, { static: true }) fil: FilDetalleVentaComponent;
  entity = "DetalleVentas"

  items: DetalleVenta[];
  item: DetalleVenta;

  proccess: Boolean;
  public idP: Number;

  constructor(private route: ActivatedRoute, private wsdl: DetalleVentaService, private router: Router) {
    this.item = new DetalleVenta();
    this.idP = this.route.snapshot.params.id;
  }

  ngOnInit() {

  }

  preNew() {
    this.item = new DetalleVenta();
    this.item.venta.id = this.idP;
  }

  select(item: DetalleVenta) {
    this.item = item;
  }



  cancel() {
    this.item = new DetalleVenta();
    this.close.nativeElement.click();
    this.fil.list();
  }

  async setResultCancel(event: boolean) {
    UturuncoUtils.showToas("Operación cancelada", "info");
    this.close.nativeElement.click();
  }

  setResult(event: any) {
    this.cancel();
  }

  preDelete(item: DetalleVenta) {
    this.item = new DetalleVenta();
    this.item = item;

    Swal.fire({
      title: 'Esta Seguro?',
      text: '¡No podrás recuperar este archivo ' + item.mercaderia.nombre + '!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '¡Sí, bórralo!',
      cancelButtonText: 'No, mantenlo',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        this.delete()
      } else if (result.dismiss === Swal.DismissReason.cancel) {

        UturuncoUtils.showToas('Tu archivo esta seguro :)', "warning")

      }
    })
  }

  async delete() {
    try {
      this.proccess = true;
      const res = await this.wsdl.doDelete(this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));
      if (result.code == 200) {
        UturuncoUtils.showToas(result.msg, "success");
        this.cancel();
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    this.proccess = false;
  }

  doFound(event: DetalleVenta[]) {
    this.items = event;
  }

}

<legend>
    Detalle de caja
</legend>

<div class="row">
    <div class="col-md-6">CIERRE SISTEMA</div>
    <div class="col-md-6">{{caja?.saldo | currency:'$ '}}</div>
    <div class="col-md-6">CIERRE CAJA OPERADOR </div>
    <div class="col-md-6">{{caja?.importe | currency:'$ '}}</div>
    <div class="col-md-6">GASTOS</div>
    <div class="col-md-6">{{caja?.egresos | currency:'$ '}}</div>
    <div class="col-md-6">INGRESOS</div>
    <div class="col-md-6">{{caja?.ingresos | currency:'$ '}}</div>
    <div class="col-md-6">VENTAS CON DEBITOS</div>
    <div class="col-md-6">{{caja?.debitos | currency:'$ '}}</div>

</div>

<hr>
<legend>Detalle de ventas</legend>
<ng-container *ngFor="let item of grpVenta | keyvalue">
    <div class="row">
        <div class="col-md-6">{{item.key}}</div>
        <div class="col-md-6">{{item.value | currency:'$ '}}</div>
    </div>
</ng-container>

<hr>
<legend>
    Detalle de caja
</legend>
<table class="table">
    <thead class="thead-inverse">
        <tr>
            <th>Fecha</th>
            <th>Descripción</th>
            <th>Importe</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of detalleCaja" [ngClass]="{'table-danger': item.tipoMovimiento=='e'}">
            <td>{{item.created_at | date :'dd-MM-yyyy'}}</td>
            <td>{{item.descripcion}}</td>
            <td class="text-right">{{item.importe | currency:'$ '}}</td>
        </tr>

    </tbody>

</table>
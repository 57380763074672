<div class="row">
    <div class="col-md-4 text-center">
        <img class="logo rounded mx-auto d-block" src="./assets/img/logo.png" alt="">
        <span style="left: 114.752px; top: 105.967px; font-size: 12px;font-weight: bold; font-family: sans-serif; transform: scaleX(1.01324);">Uturunco
            Studio</span>
        <br>
        <span style="left: 141.147px; top: 119.605px; font-size: 10.72px; font-family: sans-serif; transform: scaleX(0.996166);">Empresa
            independiente
            de desarrollo software</span>
    </div>
    <div class="col-md-4 text-center">
        <h4>Reporte por producto</h4>
        <h6>App CONTROL STOCK</h6>

    </div>
    <div class="col-md-4">
    </div>
</div>

<div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-6 text-center">
        <label>
            Resistencia, {{fecha | date:"dd-MM-yyyy"}}
        </label>
    </div>
    <!-- <div class="col-md-12 text-center">
      <h4>Listado de Stock</h4>
  </div> -->
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                &nbsp;
            </div>
            <div class="col-md-12">
                <div class="row" id="callapseGrupoFil">
                    <div class="col-md-5">

                        <app-cb-mercaderia [require]="true" [disable]="false" (select)="itemSel($event)">
                        </app-cb-mercaderia>

                    </div>
                    <div class="col-md-3">

                        <div class="form-group">
                            <label for="">Fecha desde</label>
                            <input name="fechade" oninvalid="setCustomValidity('El campo fecha es requerido')" oninput="setCustomValidity('')" type="date" class="form-control form-control-line" id="fechade" placeholder="dd/mm/aaaa" [value]="fechade | date: 'yyyy-MM-dd'" (input)="fechade = parseDate($event.target.value)"
                                required />

                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="">Fecha hasta</label>
                        <div class="form-group">
                            <input name="fechaha" oninvalid="setCustomValidity('El campo fecha es requerido')" oninput="setCustomValidity('')" type="date" class="form-control form-control-line" id="fechaha" placeholder="dd/mm/aaaa" [value]="fechaha | date: 'yyyy-MM-dd'" (input)="fechaha = parseDate($event.target.value)"
                                required />
                        </div>
                    </div>


                    <div class="col-md-1">
                        <form ngNativeValidate #f="ngForm">
                            <div id="custom-search-input">
                                <div class="col-md-12 mb-2">
                                    <label for="">&nbsp;</label>
                                    <div class="form-group">
                                        <button class="btn btn-danger" (click)="getList()" type="submit">
                                            <span class=" fas fa-search"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

        <table id="tblData" class="table table-inverse">
            <thead class="thead-inverse">
                <tr>
                    <th>Tipo transacción</th>
                    <th>Usuario</th>
                    <th>Saldo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items | keyvalue">
                    <td>Ventas desde {{fechade | date:'dd-MM-yy'}} hasta {{fechaha | date:'dd-MM-yy'}}</td>
                    <td scope="row">{{item?.key}}</td>
                    <td>{{item?.value | currency:'$ '}}</td>
                </tr>
                <tr *ngFor="let item of ganancia | keyvalue">
                    <td>Ganancia desde {{fechade | date:'dd-MM-yy'}} hasta {{fechaha | date:'dd-MM-yy'}}</td>
                    <td scope="row">{{item?.key}}</td>
                    <td>{{item?.value | currency:'$ '}}</td>
                </tr>

            </tbody>
        </table>

    </div>
</div>
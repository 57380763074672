import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Caja, Usuario } from 'src/app/model/index.model';
import { CajaService, UsuarioService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import * as moment from 'moment';

@Component({
  selector: 'app-fil-caja',
  templateUrl: './fil-caja.component.html',
  styleUrls: ['./fil-caja.component.scss']
})
export class FilCajaComponent implements OnInit {

  @Output()
  filter: EventEmitter<Caja[]> = new EventEmitter<Caja[]>();

  proccess: Boolean;
  public search: String;
  public oldSearch: String;

  usuario: Usuario[];


  fecha: any = new Date();
  fechaha: any = new Date();
  useFecha: Boolean = false;


  parseDate(fecha) {
    if (moment(fecha).isValid()) {
      return moment(fecha);
    }
  }

  constructor(private wsdl: CajaService, private wsdlu: UsuarioService) {
    this.proccess = false;
  }

  ngOnInit() {
    this.listUsu();
    this.list()
  }

  async list() {
    this.proccess = true;

    if (this.search === undefined) {
      this.search = "";
    }
    let d = this.oldSearch !== this.search;
    if (d) {

      this.oldSearch = this.search;
    }

    this.fecha = moment(this.fecha).format("YYYY-MM-DD") + " 00:00:00";
    this.fechaha = moment(this.fechaha).format("YYYY-MM-DD") + " 23:59:00";

    let c = this.search;
    let data = await this.wsdl.isCajaByUserAndFecha(c, this.fecha, this.fechaha).then();
    console.log(this.search, data);
    const result = JSON.parse(JSON.stringify(data));
    if (result.code == 200) {
      this.filter.emit(result.data);
    } else if (result.code === 666) {
      // logout app o refresh token

    } else {
      this.filter.emit([]);
      UturuncoUtils.showToas(result.msg, "error");
    }
    this.proccess = false;

  }

  async listUsu() {
    this.proccess = true;

    let data = await this.wsdlu.getList(1, 100).then();

    const result = JSON.parse(JSON.stringify(data));

    if (result.code == 200) {
      this.usuario = result.data.docs;
    } else if (result.code === 666) {
      // logout app o refresh token
    } else {
      this.usuario = [];
      UturuncoUtils.showToas(result.msg, "error");
    }
    this.proccess = false;

  }

}

import { Component, OnInit } from '@angular/core';
import { DetalleVenta } from 'src/app/model/index.model';
import { DetalleVentaService } from 'src/app/service/index.service';

@Component({
  selector: 'app-reporte-entrega',
  templateUrl: './reporte-entrega.component.html',
  styleUrls: ['./reporte-entrega.component.css']
})
export class ReporteEntregaComponent implements OnInit {

  items: DetalleVenta[];
  entity = "Entregas"
  fecha = new Date();
  proccess: Boolean;

  constructor() {
    this.items = [];
  }

  ngOnInit() {

  }

  doFound(event) {
    this.items = event;
  }


  // para exportar a excel
  exportTableToExcel(tableID, filename = '') {
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';

    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    // Specify file name
    filename = filename ? filename + '.xlsx' : 'excel_data.xlsx';

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(['ufeff', tableHTML], {
        type: dataType
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }

}

<a class="label label-primary" data-toggle="collapse" href="#callapseGrupoFil" role="button" aria-expanded="false" aria-controls="callapseGrupoFil">
    Filtrar
</a>
<div class="row" id="callapseGrupoFil">

    <div class="col-md-2">

        <div class="form-check">
            <label class="form-check-label">
                <input type="checkbox" class="form-check-input" name="useFecha" id="useFecha" [(ngModel)]="useFecha"
                    checked>
                usar fecha
            </label>
        </div>

    </div>
    <div class="col-md-3">

        <div class="form-group" *ngIf="useFecha">
            <input name="fecha" oninvalid="setCustomValidity('El campo fecha es requerido')" oninput="setCustomValidity('')" type="date" class="form-control form-control-line" id="fecha" placeholder="dd/mm/aaaa" [value]="fecha | date: 'yyyy-MM-dd'" (input)="fecha = parseDate($event.target.value)"
                required />

        </div>
    </div>
    <div class="col-md-3">

        <div class="form-group" *ngIf="useFecha">
            <input name="fechaha" oninvalid="setCustomValidity('El campo fecha es requerido')" oninput="setCustomValidity('')" type="date" class="form-control form-control-line" id="fechaha" placeholder="dd/mm/aaaa" [value]="fechaha | date: 'yyyy-MM-dd'" (input)="fechaha = parseDate($event.target.value)"
                required />

        </div>
    </div>
    <div class="col-md-4">
        <form ngNativeValidate #f="ngForm">
            <div id="custom-search-input">
                <div class="input-group col-md-12 mb-2">
                    <select width="100%" class="form-control" [(ngModel)]="search" name="search" id="search">
                        <option *ngFor="let item of usuario" [value]="item.id">{{item.usuario}}</option>
                    </select>
                    <span class="input-group-btn">
                        <button class="btn btn-danger" (click)="list()" type="submit">
                            <span class=" fas fa-search"></span>
                    </button>
                    </span>
                </div>
            </div>
        </form>
    </div>
</div>
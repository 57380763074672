import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { DetalleIngreso, Mercaderia } from 'src/app/model/index.model';
import { DetalleIngresoService, MercaderiaService } from 'src/app/service/index.service';

@Component({
  selector: 'app-frm-detalle-ingreso',
  templateUrl: './frm-detalle-ingreso.component.html',
  styleUrls: ['./frm-detalle-ingreso.component.css']
})
export class FrmDetalleIngresoComponent implements OnInit {
  /*
   * Varaiables de control para comportamientos del componente
   */
  @Output()
  finalizado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output()
  cancelado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  /*
   * control de operaciones a realizar
   */
  proccess: Boolean;

  public entity = 'detalleIngreso';
  public item: DetalleIngreso;
  private id: Number;

  constructor(private route: ActivatedRoute, private router: Router, private wsdl: DetalleIngresoService, private wsdlme: MercaderiaService) {
    this.item = new DetalleIngreso();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
  }

  @Input()
  set select(item: DetalleIngreso) {
    if (item.id === undefined) {
      this.item = new DetalleIngreso();
      this.item.ingreso.id = item.ingreso.id;
      this.id = 0;
    } else {
      this.item = new DetalleIngreso();
      this.item = item;
      this.id = item.id;
    }
  }

  doAction(f: NgForm) {
    /* validar */
    this.item.usuario = JSON.parse(UturuncoUtils.getSession("user"))

    if (f.valid) {
      if (this.id > 0) {
        //editar
        this.doEdit();
      } else {
        this.doCreate();
      }
    } else {
      alert('Validar');
    }
  }

  async doEdit() {
    try {

      this.proccess = true;
      const res = await this.wsdl.doUpdate(this.item, this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        UturuncoUtils.showToas("Se actualizado correctemte", "success");
        let res = await this.wsdlme.doCoste(this.item.mercaderia.id, this.item.precio, this.item.precio2).then();

        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    this.proccess = false;
  }

  async doCreate() {
    try {

      this.proccess = true;
      const res = await this.wsdl.doInsert(this.item).then();

      const result = JSON.parse(JSON.stringify(res));
      if (result.code == 200) {
        // this.item = result.data;
        UturuncoUtils.showToas("Se creo correctemte", "success");
        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
  }

  back() {
    this.router.navigateByUrl("admin/" + this.entity.toLowerCase() + "/lst/");
  }

  itemSel(event: Mercaderia) {
    if (event.id !== undefined) {
      this.item.mercaderia = event;
    }
  }
}


import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Mercaderia } from 'src/app/model/index.model';
import { MercaderiaService } from 'src/app/service/index.service';
import { FilMercaderiaComponent } from '../../filtro/fil-mercaderia/fil-mercaderia.component';

@Component({
  selector: 'app-cb-mercaderia-2',
  templateUrl: './cb-mercaderia-2.component.html',
  styleUrls: ['./cb-mercaderia-2.component.css']
})
export class CbMercaderia2Component implements OnInit {

  @ViewChild("closed", { static: false }) closed: ElementRef;
  @ViewChild("txtcode", { static: false }) txtcode: ElementRef;
  @ViewChild("lunch", { static: false }) lunch: ElementRef;


  @ViewChild(FilMercaderiaComponent, { static: false }) fil: FilMercaderiaComponent;


  @Output() select = new EventEmitter<Mercaderia>();
  @Input() require

  item: Mercaderia;
  items: Mercaderia[];
  focus: boolean = false;

  codigo: String;

  public entity = "Mercaderia";

  focusss() {

    this.fil.search = this.codigo;
    this.fil.list();
    this.fil.focusable();
    this.lunch.nativeElement.click();


  }

  constructor(private wsdl: MercaderiaService) {

  }

  ngOnInit() {

  }

  @Input()
  set dibujar(item: Mercaderia) {
    if (item != undefined) {
      this.item = item;
    } else {
      this.item = new Mercaderia();
      this.item.nombre = ""
      this.codigo = "";
    }
  }

  @Input()
  foco(b: Boolean) {

    if (b) {
      this.item = new Mercaderia();
      this.item.nombre = ""
      this.codigo = "";
      this.txtcode.nativeElement.focus();
    }
  }

  compareFn(c1: Mercaderia, c2: Mercaderia): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }


  async filter(f: NgForm) {
    if (f.valid) {
      let data = await this.wsdl.doFindCode(this.codigo).then()
      let res = JSON.parse(JSON.stringify(data));
      if (res.code == 200) {
        this.item = new Mercaderia();
        if (res.data) {
          this.item = res.data;
          this.select.emit(this.item);
        } else {
          this.item = new Mercaderia();
          this.item.nombre = "no se encontro producto";
        }
      } else {
        this.item = new Mercaderia();
        this.item.nombre = "no se encontro producto";

      }

    } else {
      alert('Validar');
    }
  }

  /* filtro customer */

  doFound(event: Mercaderia[]) {
    this.items = event;
  }

  selected(item) {
    this.closed.nativeElement.click()
    this.select.emit(item);
  }

}


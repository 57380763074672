<div class="row">
    <div class="col-md-12">
        <form #f="ngForm" (ngSubmit)="doAction(f)" ngNativeValidate class="was-validated">
            <fieldset>
                <h3>Formulario de alta | modificación {{ entity }}</h3>
            </fieldset>
            <div class="form-group">
                <label for="">Nombre de Grupo</label>
                <input type="text" required="true" name="grupo" id="grupo" [(ngModel)]="item.nombre" class="form-control" placeholder="" aria-describedby="helpId" />
                <small id="helpId" class="text-muted">Ingrese Nombre de Grupo</small>
            </div>

            <app-cb-categoria [dibujar]="item.categoria" (select)="itemSel($event)"></app-cb-categoria>

            <button type="submit" name="btnA" id="btnA" class="btn btn-primary btn-lg btn-block">
                <i class="fas fa-check"></i>
                aceptar
            </button>
        </form>

    </div>
    &nbsp;
    <div class="col-md-12">
        <a [routerLink]="[ '/admin/'+entity+'/lst' ]" class="btn btn-secondary btn-lg btn-block">
            <i class="fas fa-arrow-left    "></i> Volver
        </a>
    </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";

import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { FormsModule } from '@angular/forms';


import { LstGrupoComponent } from './componentes/listado/lst-grupo/lst-grupo.component';
import { FrmGrupoComponent } from './componentes/formulario/frm-grupo/frm-grupo.component';
import { FilGrupoComponent } from './componentes/filtro/fil-grupo/fil-grupo.component';
import { CbGrupoComponent } from './componentes/combo/cb-grupo/cb-grupo.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LstVentaComponent } from './componentes/listado/lst-venta/lst-venta.component';
import { FrmVentaComponent } from './componentes/formulario/frm-venta/frm-venta.component';
import { FilVentaComponent } from './componentes/filtro/fil-venta/fil-venta.component';
import { LstIngresoComponent } from './componentes/listado/lst-ingreso/lst-ingreso.component';
import { FrmIngresoComponent } from './componentes/formulario/frm-ingreso/frm-ingreso.component';
import { FilIngresoComponent } from './componentes/filtro/fil-ingreso/fil-ingreso.component';
import { FrmCategoriaComponent } from './componentes/formulario/frm-categoria/frm-categoria.component';
import { FilCategoriaComponent } from './componentes/filtro/fil-categoria/fil-categoria.component';
import { CbCategoriaComponent } from './componentes/combo/cb-categoria/cb-categoria.component';
import { LstCategoriaComponent } from './componentes/listado/lst-categoria/lst-categoria.component';
import { LstMercaderiaComponent } from './componentes/listado/lst-mercaderia/lst-mercaderia.component';
import { FrmMercaderiaComponent } from './componentes/formulario/frm-mercaderia/frm-mercaderia.component';
import { FilMercaderiaComponent } from './componentes/filtro/fil-mercaderia/fil-mercaderia.component';
import { CbMercaderiaComponent } from './componentes/combo/cb-mercaderia/cb-mercaderia.component';
import { GstVentaComponent } from './componentes/listado/gst-pedido/gst-venta.component';
import { LstDetalleVentaComponent } from './componentes/listado/lst-detalle-entrega/lst-detalle-entrega.component';
import { FrmDetalleVentaComponent } from './componentes/formulario/frm-detalle-venta/frm-detalle-venta.component';
import { FilDetalleVentaComponent } from './componentes/filtro/fil-detalle-entrega/fil-detalle-entrega.component';
import { CbMercaderia2Component } from './componentes/combo/cb-mercaderia-2/cb-mercaderia-2.component';
import { DetalleCompraComponent } from './componentes/custom/detalle-compra/detalle-compra.component';
import { DetalleVentaComponent } from './componentes/custom/detalle-venta/detalle-venta.component';
import { FrmDetalleIngresoComponent } from './componentes/formulario/frm-detalle-ingreso/frm-detalle-ingreso.component';
import { ReporteStockComponent } from './componentes/reporte/reporte-stock/reporte-stock.component';
import { ReporteEntregaComponent } from './componentes/reporte/reporte-entrega/reporte-entrega.component';
import { FilDetalleVenta2Component } from './componentes/reporte/reporte-entrega/fil-detalle-entrega-2/fil-detalle-entrega-2.component';
import { FilUsuarioComponent } from './componentes/filtro/fil-usuario/fil-usuario.component';
import { FrmUsuarioComponent } from './componentes/formulario/frm-usuario/frm-usuario.component';
import { LstUsuarioComponent } from './componentes/listado/lst-usuario/lst-usuario.component';
import { CbRolComponent } from './componentes/combo/cb-rol/cb-rol.component';
import { FilDetalleCajaComponent } from './componentes/filtro/fil-detalle-caja/fil-detalle-caja.component';
import { LstDetalleCajaComponent } from './componentes/custom/detalle-caja/detalle-caja.component';
import { FrmDetalleCajaComponent } from './componentes/formulario/frm-detalle-caja/frm-detalle-caja.component';
import { FilVentaUsuarioComponent } from './componentes/filtro/fil-venta-usuario/fil-venta-usuario.component';
import { ReporteCajaComponent } from './componentes/reporte/reporte-caja/reporte-caja.component';
import { FilCajaComponent } from './componentes/filtro/fil-caja/fil-caja.component';
import { DetalleCaja2Component } from './componentes/reporte/reporte-caja/detalle-caja/detalle-caja.component';
import { RoleAuthService } from 'src/app/auth/role';
import { RefreshGuard } from 'src/app/auth/auth';
import { ReporteVentasFechaComponent } from './componentes/reporte/reporte-ventas-fecha/reporte-ventas-fecha.component';
import { ReporteCategoriaComponent } from './componentes/reporte/reporte-categoria/reporte-categoria.component';
import { ReporteProductoComponent } from './componentes/reporte/reporte-producto/reporte-producto.component';


@NgModule({
   declarations: [
      AdminComponent,

      /* Categoria */
      LstCategoriaComponent,
      FrmCategoriaComponent,
      FilCategoriaComponent,
      CbCategoriaComponent,


      /* Grupos */
      LstGrupoComponent,
      FrmGrupoComponent,
      FilGrupoComponent,
      CbGrupoComponent,

      /* Mercaderiaes */
      LstMercaderiaComponent,
      FrmMercaderiaComponent,
      FilMercaderiaComponent,
      CbMercaderiaComponent,

      /* Ventas */
      LstVentaComponent,
      FrmVentaComponent,
      FilVentaComponent,
      FilVentaUsuarioComponent,

      /* DetalleVentas */
      GstVentaComponent,
      LstDetalleVentaComponent,
      FrmDetalleVentaComponent,
      FilDetalleVentaComponent,


      LstDetalleVentaComponent,
      FrmDetalleVentaComponent,
      FilDetalleVentaComponent,

      /* Ingreso */
      LstIngresoComponent,
      FrmIngresoComponent,
      FilIngresoComponent,

      CbMercaderia2Component,
      DetalleCompraComponent,
      DetalleVentaComponent,
      FrmDetalleIngresoComponent,

      ReporteStockComponent,
      ReporteEntregaComponent,
      ReporteCajaComponent,
      FilDetalleVenta2Component,
      FilCajaComponent,


      FilUsuarioComponent,
      FrmUsuarioComponent,
      LstUsuarioComponent,
      CbRolComponent,

      FilDetalleCajaComponent,
      LstDetalleCajaComponent,
      FrmDetalleCajaComponent,


      DetalleCaja2Component,
      ReporteVentasFechaComponent,
      ReporteCategoriaComponent,
      ReporteProductoComponent,
   ],
   imports: [
      HttpClientModule,
      BrowserModule,
      SharedModule,
      AdminRoutingModule,
      FormsModule,
   ],
   exports: [

   ],
   providers: [RoleAuthService, RefreshGuard],
   schemas: [
      CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
   ],
})
export class AdminModule { }
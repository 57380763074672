import { Usuario } from './usuario';

export class Caja {
  id: Number;
  usuario: Usuario;
  isOpen: Boolean;
  importe: Number;
  saldo: Number;
  ingresos: Number;
  egresos: Number;
  debitos: Number;

  constructor() {
  }
}
import { Injectable } from '@angular/core';
import { UturuncoUtils } from '../../utils/uturuncoUtils';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    other_header;
    api;

    constructor(private http: HttpClient) {
        this.api = environment.wsdlUrl + "apiV3";
    }



    finTablero() {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .get(this.api + "/tablero", { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

}

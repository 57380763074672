import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { FrmGrupoComponent } from './componentes/formulario/frm-grupo/frm-grupo.component';
import { LstGrupoComponent } from './componentes/listado/lst-grupo/lst-grupo.component';
import { FrmVentaComponent } from './componentes/formulario/frm-venta/frm-venta.component';
import { LstVentaComponent } from './componentes/listado/lst-venta/lst-venta.component';
import { FrmDetalleVentaComponent } from './componentes/formulario/frm-detalle-venta/frm-detalle-venta.component';
import { FrmIngresoComponent } from './componentes/formulario/frm-ingreso/frm-ingreso.component';
import { LstIngresoComponent } from './componentes/listado/lst-ingreso/lst-ingreso.component';
import { FrmCategoriaComponent } from './componentes/formulario/frm-categoria/frm-categoria.component';
import { LstCategoriaComponent } from './componentes/listado/lst-categoria/lst-categoria.component';
import { FrmMercaderiaComponent } from './componentes/formulario/frm-mercaderia/frm-mercaderia.component';
import { LstMercaderiaComponent } from './componentes/listado/lst-mercaderia/lst-mercaderia.component';
import { GstVentaComponent } from './componentes/listado/gst-pedido/gst-venta.component';
import { ReporteStockComponent } from './componentes/reporte/reporte-stock/reporte-stock.component';
import { ReporteEntregaComponent } from './componentes/reporte/reporte-entrega/reporte-entrega.component';
import { FrmUsuarioComponent } from './componentes/formulario/frm-usuario/frm-usuario.component';
import { LstUsuarioComponent } from './componentes/listado/lst-usuario/lst-usuario.component';
import { FrmDetalleCajaComponent } from './componentes/formulario/frm-detalle-caja/frm-detalle-caja.component';
import { LstDetalleCajaComponent } from './componentes/custom/detalle-caja/detalle-caja.component';
import { ReporteCajaComponent } from './componentes/reporte/reporte-caja/reporte-caja.component';
import { RoleAuthService } from 'src/app/auth/role';
import { RefreshGuard } from 'src/app/auth/auth';
import { ReporteVentasFechaComponent } from './componentes/reporte/reporte-ventas-fecha/reporte-ventas-fecha.component';
import { ReporteCategoriaComponent } from './componentes/reporte/reporte-categoria/reporte-categoria.component';
import { ReporteProductoComponent } from './componentes/reporte/reporte-producto/reporte-producto.component';

const routes: Routes = [
  {
    path: "admin", component: AdminComponent, children:
      [
        {
          path: "categorias", children: [
            {
              path: "abm/:id", component: FrmCategoriaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
            {
              path: "lst", component: LstCategoriaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
          ]
        },
        {
          path: "grupos", children: [
            {
              path: "abm/:id", component: FrmGrupoComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
            {
              path: "lst", component: LstGrupoComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
          ]
        },
        {
          path: "mercaderias", children: [
            {
              path: "abm/:id", component: FrmMercaderiaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 3] }
            },
            {
              path: "lst", component: LstMercaderiaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 2, 3] }
            },
          ]
        },
        {
          path: "ventas", children: [
            {
              path: "abm/:id", component: FrmVentaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 2, 3] }
            },
            {
              path: "lst", component: LstVentaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 2, 3] }
            },
          ]
        },
        {
          path: "detalleVentas", children: [
            {
              path: "abm/:id", component: FrmDetalleVentaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 2, 3] }
            },
            {
              path: "gst/:id", component: GstVentaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
          ]
        },
        {
          path: "ingresos", children: [
            {
              path: "abm/:id", component: FrmIngresoComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 3] }
            },
            {
              path: "lst", component: LstIngresoComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 3] }
            },
          ]
        },
        {
          path: "usuarios", children: [
            {
              path: "abm/:id", component: FrmUsuarioComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
            {
              path: "lst", component: LstUsuarioComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
          ]
        },
        {
          path: "detallecajas", children: [
            {
              path: "abm/:id", component: FrmDetalleCajaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 2, 3] }
            },
            {
              path: "lst", component: LstDetalleCajaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 2, 3] }
            },
          ]
        },
        {
          path: "reporte", children: [
            {
              path: "stock", component: ReporteStockComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1, 2, 3] }
            },
            {
              path: "entrega", component: ReporteEntregaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
            {
              path: "caja", component: ReporteCajaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
            {
              path: "total", component: ReporteVentasFechaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
            {
              path: "categoria", component: ReporteCategoriaComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
            {
              path: "producto", component: ReporteProductoComponent, pathMatch: 'full', canActivate: [RefreshGuard, RoleAuthService],
              data: { roles: [1] }
            },
          ]
        },



      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }

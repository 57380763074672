import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Mercaderia, DetalleVenta } from 'src/app/model/index.model';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { CbMercaderiaComponent } from '../../combo/cb-mercaderia/cb-mercaderia.component';
import { CbMercaderia2Component } from '../../combo/cb-mercaderia-2/cb-mercaderia-2.component';
import { DetalleVentaService } from 'src/app/service/index.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detalle-venta',
  templateUrl: './detalle-venta.component.html',
  styleUrls: ['./detalle-venta.component.css']
})
export class DetalleVentaComponent implements OnInit {

  @ViewChild(CbMercaderia2Component) gr: CbMercaderia2Component;

  @ViewChild("cant", { static: false }) cant: ElementRef;
  @ViewChild("pre", { static: false }) prec: ElementRef;

  @Output() compra = new EventEmitter<DetalleVenta[]>();

  @Input()
  set items(event: DetalleVenta[]) {
    if (event) {
      this.detalle = event;
    }
  }
  cantidad: Number = 0;
  precio: Number = 0;
  mercaderia: Mercaderia;
  detalle: DetalleVenta[];

  constructor(private wsdldi: DetalleVentaService) {
    this.detalle = [];
    this.cantidad = 1;
  }

  ngOnInit() {

  }

  itemSel(event: Mercaderia) {

    if (event.id) {
      this.mercaderia = event;
      this.precio = event.precioVen;
      this.addRow();
    } else {
      // UturuncoUtils.showToas("no encontro datos", "info")
    }

  }

  pase() {
    this.prec.nativeElement.focus()
  }

  async addRow() {

    if (this.mercaderia.id == undefined || this.cantidad <= 0) {
      UturuncoUtils.showToas("debe seleccionar un mercaderia y agregar una cantidad", "error")
      return;
    }
    if (this.precio == 0) {
      let res = await Swal.fire({
        title: 'Ingrese importe de la venta',
        input: 'number',
        inputAttributes: {
          autocapitalize: 'off'
        },
        confirmButtonText: 'aceptar',
      }).then()

      if (res.value) {
        this.precio = res.value;
      }

    }
    // let control = this.detalle.find((e) => e.mercaderia.id == this.mercaderia.id);

    // let repetido = this.detalle.filter(e => {

    //   if (e.mercaderia.id == this.mercaderia.id) {
    //     e.cantidad = parseInt(e.cantidad + "");
    //   }

    // });

    let repeat = false;

    this.detalle = this.detalle.map((e) => {
      if (e.mercaderia.id == this.mercaderia.id) {
        repeat = true;
        return {
          ...e,
          cantidad: parseInt(e.cantidad + "") + parseInt("" + this.cantidad),
          precio: ((parseInt(e.cantidad + "") + parseInt("" + this.cantidad)) >= this.mercaderia.cantOferta) ? this.mercaderia.precioOfe : this.precio,
          ganancia: parseFloat("" + e.precio) - parseFloat("" + this.mercaderia.precioCom),
        };
      }
      return e;

    });

    // let cantidades = [];

    // cantidades = repetido.map(a => a.cantidad);
    // >let total = this.cantidad;

    // if (cantidades.length > 0) {
    //   total += cantidades.reduce((ant, act) => {
    //     return parseInt("" + ant) + parseInt("" + act);
    //   })
    // }


    //  if (total <= this.mercaderia.stock) {

    // if (total >= this.mercaderia.cantOferta) {
    //   this.precio = this.mercaderia.precioOfe;
    // }
    if (repeat) {
      this.compra.emit(this.detalle)
      this.mercaderia = new Mercaderia();
      this.cantidad = 1;
      this.gr.foco(true);
    } else {
      if (this.cantidad >= this.mercaderia.cantOferta) {
        this.precio = this.mercaderia.precioOfe;
      }
      let det = new DetalleVenta();
      det.cantidad = this.cantidad;
      det.mercaderia = this.mercaderia;
      det.precio = this.precio;
      if (this.mercaderia.precioVen == 0) {
        det.ganancia = parseFloat("" + this.precio) * 0.20;
      } else {
        det.ganancia = parseFloat("" + this.precio) - parseFloat("" + this.mercaderia.precioCom);
      }

      det.usuario = JSON.parse(UturuncoUtils.getSession("user"));
      det.descripcion = this.mercaderia.grupo.categoria.nombre;
      this.detalle.unshift(det);
      this.compra.emit(this.detalle)
      this.mercaderia = new Mercaderia();
      this.cantidad = 1;
      this.gr.foco(true);

    }

    // } else {
    //   UturuncoUtils.showToas("No tiene stock sufuciete tiene solamente " + this.mercaderia.stock + " y su venta tiene " + total + " de este producto", "info");
    // }

    // if (control == undefined) {

    // } else {
    //   UturuncoUtils.showToas("Este elemento ya existe en tu lista", "info");
    // }

  }

  async remove(det) {
    if (det.id > 0) {
      await this.wsdldi.doDelete(det.id).then();
    }
    this.detalle = this.detalle.filter(e => e != det);
    this.compra.emit(this.detalle)

  }

}

import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DetalleCajaService } from 'src/app/service/index.service';
import { DetalleCaja, Caja } from 'src/app/model/index.model';

@Component({
  selector: 'app-detalle-caja-2',
  templateUrl: './detalle-caja.component.html',
  styleUrls: ['./detalle-caja.component.css']
})
export class DetalleCaja2Component implements OnInit {

  @Input() idCaja;

  ngOnChanges(changes: SimpleChanges): void {
    this.doFind();
  }

  public caja: Caja;
  public grpVenta: any[];
  public detalleCaja: DetalleCaja[];

  constructor(private wsdl: DetalleCajaService) {

  }

  ngOnInit() {
  }

  async doFind() {
    try {
      let data = await this.wsdl.getDetaleCaja(this.idCaja).then();

      let res = JSON.parse(JSON.stringify(data));
      if (res.code == 200) {
        this.grpVenta = res.data.ventas;
        this.caja = res.data.caja
        this.detalleCaja = res.data.detalle;
      }

    } catch (error) {

    } finally {

    }
  }

}

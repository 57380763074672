import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import Swal from 'sweetalert2'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UsuarioService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(900)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})

export class LoginComponent implements OnInit {

  //  @ViewChild('closed', { static: true }) closed: ElementRef;
  @ViewChild('closedRestore', { static: false }) closedRestore: ElementRef;
  @ViewChild('usercuit', { static: false }) usercuit: ElementRef;
  @ViewChild('password', { static: false }) password: ElementRef;
  @ViewChild('datosss', { static: false }) registrobtn: ElementRef;



  public proccess: Boolean;
  public isUser: boolean;
  public download: String;
  public cuit: String;
  public pass: String;
  public anim;

  public error: Boolean;

  public dniRestore;
  public fechaRestore;
  public credencialRestore;

  public user: any;


  public query: String;
  public dataJSON: any;

  constructor(private route_: ActivatedRoute,
    private route: Router,
    private renderer: Renderer2,
    private wsdlu: UsuarioService) {

    this.proccess = false;
    this.error = false;
    this.dataJSON = { contacto: { Nro: '' } }
  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    this.isUser = false;

    this.route_.queryParams.subscribe(params => {

      try {
        if (params.code !== undefined && params.code.length === 'ZTNkYTk3ZmFhMDhmZDI0NjgxZTA0Yjk0OGE1Y2Y2NzE2OGFiYzg0YWM5YjEyNDBlZTQ0OTk3NzEwNThhNTE4Mw'.length) {

        }

      } catch (error) {
        this.proccess = false;
        Swal.fire('Oops...', error, 'error')
      }

    });

    // this.route_.paramMap.subscribe((params: Params) => {
    //   let dataquery = JSON.parse(params.params.state);
    //   this.query = dataquery.apellidos;
    //   this.dataJSON = dataquery;
    //   console.log("DATA", dataquery)
    //   this.cuit = dataquery.tiposDocumentoPersona[0].numeroDocumento;
    // });


  }

  async login2() {

    try {

      this.proccess = true;
      let data = await this.wsdlu.doLogin({ usuario: this.cuit, clave: this.pass }).then();

      let res = JSON.parse(JSON.stringify(data));

      if (res.code == 200) {

        UturuncoUtils.showToas('Mercaderiavenido Sr/a:' + res.data.usuario, 'success');

        UturuncoUtils.setSession("user", JSON.stringify(res.data));
        UturuncoUtils.setSession("caja", JSON.stringify(res.caja));
        UturuncoUtils.setToken(res.token);
        UturuncoUtils.setSession("refresh", res.refresh);

        if (res.data.activo) {
          if (res.data.rol.id == 1) {
            this.route.navigate(["/tablero"]);
          } else {
            this.route.navigate(["/admin/ventas/lst"]);
          }

        } else {
          Swal.fire({
            title: 'Ingrese una nueva clave',
            input: 'password',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            showLoaderOnConfirm: true,
            preConfirm: (clave) => {
              return this.wsdlu.doChangePass(res.data.id, this.pass, `${clave}`).then((res: any) => {
                return res;

              }).catch(error => { return error });
            },
            allowOutsideClick: () => !Swal.isLoading()

          }).then((result) => {
            if (result.value.code == 200) {
              this.route.navigate(["/tablero"]);
            } else {
              UturuncoUtils.showToas("vuelva a intentar", "warning")
            }
          })
        }



      } else {
        Swal.fire('Oops...', res.msg, 'error')
      }
      this.proccess = false;

    } catch (error) {
      Swal.fire('Oops...', "Algo salio mal vuelva a intentar ", 'error')
      this.proccess = false;
    }

  }

  removeClassPass() {
    this.renderer.removeClass(this.password.nativeElement, "invisible");
    this.anim = 'in';
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  cerrar() {
    this.error = !this.error;
  }

  getRR() {
    return this.proccess;
  }

}

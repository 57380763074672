import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './shared/login/login.component';
import { TableroComponent } from './shared/tablero/tablero.component';


const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "tablero", component: TableroComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

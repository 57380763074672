<nav class="navbar navbar-expand-lg navbar-dark bg-dark flex-md-nowrap shadow  d-print-none">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <a class="navbar-brand" [routerLink]="['/tablero']">CONTROL STOCK </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/admin/mercaderias/lst']">Mercaderias<span
                        class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/admin/ingresos/lst']">Compras</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/admin/ventas/lst']" tabindex="-1">Ventas</a>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Reportes</a>
                <div class="dropdown-menu">
                    <a class="dropdown-item" [routerLink]="['/admin/reporte/stock']">
                        Stock
                    </a>
                    <a class="dropdown-item" [routerLink]="['/admin/reporte/entrega']">
                        Por Usuario
                    </a>
                    <a class="dropdown-item" [routerLink]="['/admin/reporte/total']">
                        Total por Usuario
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" [routerLink]="['/admin/reporte/caja']">
                        Total por Usuario
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" [routerLink]="['/admin/reporte/categoria']">
                        Total por categorias
                    </a>
                    <a class="dropdown-item" [routerLink]="['/admin/reporte/producto']">
                        Total por producto
                    </a>

                </div>
            </li>

        </ul>

        <form class="form-inline my-2 my-lg-0">
            <input style="background-color: #333; color: chartreuse;" class="form-control mr-sm-2" readonly disabled type="search" placeholder="Search" [value]="getuser()?.nombre | uppercase" aria-label="Search">
            <button (click)="cerrar()" class="btn btn-outline-danger my-2 my-sm-0" type="submit">cerrar</button>
        </form>
    </div>
</nav>
import { Component, OnInit } from '@angular/core';
import { MercaderiaService } from 'src/app/service/index.service';
import { Mercaderia } from 'src/app/model/index.model';

@Component({
  selector: 'app-reporte-stock',
  templateUrl: './reporte-stock.component.html',
  styleUrls: ['./reporte-stock.component.css']
})
export class ReporteStockComponent implements OnInit {

  items: Mercaderia[];
  reporte: any[];
  fecha = new Date();
  constructor(private wsdl: MercaderiaService) {
    this.items = []
  }

  ngOnInit() {
    this.getList();

  }
  async getList() {
    try {
      let data = await this.wsdl.getList(1, 10000).then();
      let resp = JSON.parse(JSON.stringify(data));
      if (resp.code == 200) {
        this.items = resp.data.docs;
        this.reporte = this.items.reduce((prev, current, index, arr) => {
          let exists = prev.find(x => x.nombre === current.grupo.nombre);

          if (!exists) {
            exists = { nombre: current.grupo.nombre, cantidad: 0, ped_stock: current.grupo.ped_stock, ent_stock: current.grupo.ent_stock, lst: [] };
            prev.push(exists);
          }

          if (current != null) {
            exists["cantidad"] = exists["cantidad"] + current.stock;
            exists.lst.push(current);
          }
          // Devuelvo el array resultado para la nueva iteración
          return prev;
        }, []);
        console.log(this.reporte)

      }
    } catch (error) {
      console.log("error", error)

    }
  }

  agrupar() {
    return this.groupBy(this.items, (c) => c.grupo.nombre);
  }

  groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminModule } from './pages/admin/admin.module';
import { FormsModule } from '@angular/forms'
import { SharedModule } from './shared/shared.module';
import { UserModule } from './pages/user/user.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptors/interceptor.service';


@NgModule({
   declarations: [
      AppComponent,
   ],
   imports: [
      BrowserModule,
      SharedModule,
      AdminModule,
      UserModule,
      AppRoutingModule,
      FormsModule,
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: InterceptorService,
         multi: true,
      },
   ],
   bootstrap: [
      AppComponent
   ],
   exports: [

   ],
   schemas: [
      CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
   ],
})
export class AppModule { }

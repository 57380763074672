import { Categoria } from './categoria';

export class Grupo {
  id: Number;
  nombre: String;
  categoria: Categoria;
  ent_stock: Number;
  ped_stock: Number;

  constructor() {
    this.categoria = new Categoria();
  }
}
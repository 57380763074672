<app-nav></app-nav>


<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>


        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4 bg-light">
            <br>
            <div class="row">

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title text-uppercase text-muted mb-0">Ventas del día</h5>
                                    <span class="h2 font-weight-bold mb-0">{{totalVenta | currency:'$ ' }}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow text-center">
                                        <i class="fas fa-chart-bar"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> {{porVenta}}%</span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>

                </div>

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title text-uppercase text-muted mb-0">Gastos del día</h5>
                                    <span class="h2 font-weight-bold mb-0">{{ totalGastos | currency:'$ ' }}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow text-center">
                                        <i class="fas fa-chart-bar"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> {{porGastos}}%</span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>

                </div>

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title text-uppercase text-muted mb-0">Ganancias del día</h5>
                                    <span class="h2 font-weight-bold mb-0">{{ totalGanancia | currency:'$ ' }}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow text-center">
                                        <i class="fas fa-chart-bar"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> {{porGanancia}}%</span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <br>
            <div class="row">

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title text-uppercase text-muted mb-0">Ventas del mes</h5>
                                    <span class="h2 font-weight-bold mb-0">{{totalVentaM | currency:'$ ' }}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow text-center">
                                        <i class="fas fa-chart-bar"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> </span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>

                </div>

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title text-uppercase text-muted mb-0">Gastos del mes</h5>
                                    <span class="h2 font-weight-bold mb-0">{{ totalGastosM | currency:'$ ' }}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow text-center">
                                        <i class="fas fa-chart-bar"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> </span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>

                </div>

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title text-uppercase text-muted mb-0">Ganancias del mes</h5>
                                    <span class="h2 font-weight-bold mb-0">{{ totalGananciaM | currency:'$ ' }}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow text-center">
                                        <i class="fas fa-chart-bar"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i></span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>


        </main>
    </div>
</div>


<!-- <br>
<br>
<div class="row">
    <div class="col-md-3">
        <a [routerLink]="['/admin/categorias/lst']">

            <div class="card border-success mx-sm-1 p-3">
                <div class="card border-success shadow text-success p-3 my-card">
                    <i class="fas fa-book"></i>
                </div>
                <div class="text-success text-center mt-3">
                    <h3>Gestionar Categorias</h3>
                </div>
            </div>
        </a>

    </div>

    <div class="col-md-3">
        <a [routerLink]="['/admin/grupos/lst']">

            <div class="card border-info mx-sm-1 p-3">
                <div class="card border-info shadow text-info p-3 my-card">
                    <i class="fas fa-book"></i>
                </div>
                <div class="text-info text-center mt-3">
                    <h3>Gestionar Grupos</h3>
                </div>
            </div>
        </a>

    </div>
    <div class="col-md-3">
        <a [routerLink]="['/admin/mercaderiaes/lst']">

            <div class="card border-success mx-sm-1 p-3">
                <div class="card border-success shadow text-success p-3 my-card">
                    <i class="fas fa-book"></i>
                </div>
                <div class="text-success text-center mt-3">
                    <h3>Gestionar Mercaderiaes</h3>
                </div>
            </div>
        </a>

    </div>
    <div class="col-md-3">
        <a [routerLink]="['/admin/ventas/lst']">

            <div class="card border-success mx-sm-1 p-3">
                <div class="card border-success shadow text-success p-3 my-card">
                    <i class="fas fa-book"></i>
                </div>
                <div class="text-success text-center mt-3">
                    <h3>Gestionar Ventas</h3>
                </div>
            </div>
        </a>

    </div>



</div>
<br>
<br>
<div class="row">
    <div class="col-md-3">
        <a [routerLink]="['/admin/ingresos/lst']">

            <div class="card border-success mx-sm-1 p-3">
                <div class="card border-success shadow text-success p-3 my-card">
                    <i class="fas fa-book"></i>
                </div>
                <div class="text-success text-center mt-3">
                    <h3>Gestionar compras</h3>
                </div>
            </div>
        </a>
    </div>
    <div class="col-md-3">
        <a [routerLink]="['/user/reporte/stock']">

            <div class="card border-success mx-sm-1 p-3">
                <div class="card border-success shadow text-success p-3 my-card">
                    <i class="fas fa-book"></i>
                </div>
                <div class="text-success text-center mt-3">
                    <h3>Reporte stock mercaderiaes</h3>
                </div>
            </div>
        </a>

    </div>
    <div class="col-md-3">
        <a [routerLink]="['/user/reporte/entrega']">

            <div class="card border-success mx-sm-1 p-3">
                <div class="card border-success shadow text-success p-3 my-card">
                    <i class="fas fa-book"></i>
                </div>
                <div class="text-success text-center mt-3">
                    <h3>Reporte entrega mercaderiaes</h3>
                </div>
            </div>
        </a>

    </div>

</div> -->
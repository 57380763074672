<div class="row">
    <div class="col-md-12">
        <form #f="ngForm" (ngSubmit)="doAction(f)" ngNativeValidate class="was-validated">
            <fieldset>
                <h3>Formulario de alta | modificación {{ entity }}</h3>
            </fieldset>

            <div class="form-group">
                <label for="">Seleccione tipo movimiento</label>
                <select (ngModelChange)="dataChanged($event)" class="form-control" [(ngModel)]="item.tipoMovimiento" name="tipoMovimiento" id="tipoMovimiento">
                    <option value="i">Ingreso</option>
                    <option value="e">Egreso</option>
                    <option value="d">Ventas con debitos</option>

                </select>
            </div>

            <div class="form-group">
                <label for="">Nombre del motivo</label>
                <input autocomplete="off" list="client" type="text" required="true" name="descripcion" id="descripcion" [(ngModel)]="item.descripcion" class="form-control" placeholder="" aria-describedby="helpId" />
                <datalist id="client">
                    <option *ngFor="let item of motivos" value="{{item}}">
                </datalist>
                <small id="helpId" class="text-muted">Ingrese aclaración del movimiento</small>

            </div>

            <div class="form-group">
                <label for="">importe</label>
                <input type="number" required="true" min="1" name="importe" id="importe" [(ngModel)]="item.importe" class="form-control" placeholder="" aria-describedby="helpId" />
                <small id="helpId" class="text-muted">Ingrese importe</small>
            </div>

            <button type="submit" name="btnA" id="btnA" class="btn btn-primary btn-lg btn-block">
                <i class="fas fa-check"></i>
                aceptar
            </button>

        </form>

    </div>
    &nbsp;
    <div class="col-md-12">
        <a [routerLink]="[ '/admin/'+entity+'/lst' ]" class="btn btn-secondary btn-lg btn-block">
            <i class="fas fa-arrow-left    "></i> Volver
        </a>
    </div>

</div>
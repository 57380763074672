import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UturuncoUtils } from '../utils/uturuncoUtils';

@Injectable()
export class RoleAuthService implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let roles = route.data["roles"] as Array<Number>;
        // console.log('data roles : '+roles[0]);
        let s = UturuncoUtils.getSession('user')
        if (s) {
            var currentuser: any;
            currentuser = JSON.parse(s);

            let bandera = false
            roles.forEach(r => {
                if (currentuser.rol.id == r) {
                    bandera = true;
                }
            });
            if (!bandera) {
                let r = state.url;
                this.router.navigate(['/admin/ventas/lst'], { queryParams: { returnUrl: state.url } });
                UturuncoUtils.showToas("No posee privilegio para ingresar aqui", "info")
            }
            return bandera;
        }
        this.router.navigate(['/admin/ventas/lst'], { queryParams: { returnUrl: state.url } });
        UturuncoUtils.showToas("No posee privilegio para ingresar aqui", "info")
        return false;
    }

}
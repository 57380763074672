<div class="row">
    <div class="col-md-12">
        <fieldset>
            <h3>Formulario de {{ entity }}</h3>
        </fieldset>
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <label for="">Nombre de Cliente</label>
                    <input autocomplete="off" list="client" type="text" required="true" name="cliente" id="cliente" [(ngModel)]="item.cliente" class="form-control" placeholder="" aria-describedby="helpId" />
                    <datalist id="client">
                        <option *ngFor="let item of clientes" value="{{item.cliente}}">
                    </datalist>
                    <small id="helpId" class="text-muted">Ingrese nombre de cliente</small>

                </div>

            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="">&nbsp;</label>
                    <h3>Total : {{total | currency:'$' }}</h3>
                    <small id="helpId" class="text-muted">&nbsp;</small>
                </div>

            </div>
        </div>

        <app-detalle-venta (compra)="venta($event)" [items]="cargainicial"></app-detalle-venta>
        <ng-container *ngIf="getRR(); then thenBlock else elseBlock"></ng-container>
        <ng-template #thenBlock>
            <div class="row">
                <div class="col-12 text-center">
                    <i class="fas fa-spinner fa-4x fa-spin"></i>
                </div>
            </div>
        </ng-template>
        <ng-template #elseBlock>
        </ng-template>
        <form #f="ngForm" (ngSubmit)="doAction(f)" ngNativeValidate class="was-validated">
            <button [disabled]="proccess" type="submit" name="btnA" id="btnA" class="btn btn-primary btn-lg btn-block">
                <i class="fas fa-check"></i>
                confirmar venta
            </button>
        </form>

    </div>
    &nbsp;
    <div class="col-md-12">
        <a [routerLink]="[ '/admin/'+entity+'/lst' ]" class="btn btn-secondary btn-lg btn-block">
            <i class="fas fa-arrow-left    "></i> Volver
        </a>
    </div>
</div>
<form #f="ngForm" (ngSubmit)="filter(f)" ngNativeValidate class="was-validated">

    <div class="form-group">
        <label for="">Ingrese codigo barra {{entity}}</label>
        <input (keydown.f2)="focusss()" #txtcode [(ngModel)]="codigo" type="text" class="form-control" name="codigo" id="codigo" aria-describedby="helpId" placeholder="código de barra" />
        <ng-container *ngIf="item.nombre=='no se encontro producto';then vacio else data">
        </ng-container>
        <ng-template #vacio>
            <small id="helpId" class="text-danger"> {{item?.nombre}} stock actual {{item?.stock}} </small>
        </ng-template>
        <ng-template #data>
            <ng-container *ngIf="item.nombre=='';then sin else prod "></ng-container>
            <ng-template #sin>
                <small id="helpId" class="text-mute"> {{entity}} </small>
            </ng-template>
            <ng-template #prod>
                <small id="helpId" class="text-success"> {{entity}} {{item?.nombre}} stock actual {{item?.stock}}
                </small>
            </ng-template>

        </ng-template>

    </div>



</form>

<!-- Button trigger modal -->
<button class="invisible" (click)="focusss()" #lunch data-toggle="modal" data-target="#modelId">

</button>

<!-- Modal -->
<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Listado Producto</h5>
                <button #closed type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-fil-mercaderia [foco]="focus" (filter)="doFound($event)">
                </app-fil-mercaderia>
                <div class="row table-responsive">
                    <table class="table table-striped table-inverse">
                        <thead class="thead-inverse">
                            <tr>
                                <!-- <th>id</th> -->
                                <th>Grupo</th>
                                <th>Nombre</th>
                                <th>Código</th>
                                <th>Stock</th>
                                <th>Percio</th>
                                <th>acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of items">
                                <!-- <td scope="row">{{item.id}} </td> -->
                                <td>{{item.grupo.nombre}}</td>
                                <td>{{item.nombre}}</td>
                                <td>{{item.serie}} {{item.descripcion}}</td>
                                <td>{{item.stock}}</td>
                                <td>{{item.precioVen}}</td>

                                <td>
                                    <button type="button" (click)="selected(item)" class="btn btn-success btn-sm">
                                        <i class="fas fa-arrow-right"></i>
                                    </button>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                &nbsp;

            </div>
        </div>
    </div>
</div>
import { Rol } from './rol';

export class Usuario {
  id: Number;
  nombre: String;
  rol: Rol;
  usuario: String;
  correo: String;
  avatar: String;
  habDesd: String;
  habHast: String;
  activo: Boolean;

  constructor() {
    this.rol = new Rol();
    this.avatar = "default.png";
    this.habDesd = "00:00";
    this.habHast = "23:59"
    this.activo = false;
  }

}
<div class="row">
    <div class="col-md-4 text-center">
        <img class="logo rounded mx-auto d-block" src="./assets/img/logo.png" alt="">
        <span style="left: 114.752px; top: 105.967px; font-size: 12px;font-weight: bold; font-family: sans-serif; transform: scaleX(1.01324);">Uturunco
            Studio</span>
        <br>
        <span style="left: 141.147px; top: 119.605px; font-size: 10.72px; font-family: sans-serif; transform: scaleX(0.996166);">Empresa
            independiente
            de desarrollo software</span>
    </div>
    <div class="col-md-4 text-center">
        <h4>Reporte de stock</h4>
        <h6>App CONTROL STOCK</h6>

    </div>
    <div class="col-md-4">
    </div>
</div>

<div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-6 text-center">
        <label>
            Resistencia, {{fecha | date:"dd-MM-yyyy"}}
        </label>
    </div>
    <!-- <div class="col-md-12 text-center">
        <h4>Listado de Stock</h4>
    </div> -->
    <div class="col-md-12">


        <ng-container *ngFor="let item of reporte;let i=index">
            <table id="tblData" class="table table-striped table-inverse">
                <thead class="thead-inverse">

                    <tr class="d-flex" *ngIf="i==0">
                        <th class="col-9">Nombre del mercaderia </th>
                        <!-- <th class="col-1 d-print-none">(p - e)</th> -->
                        <th class="col-2">Stock acutal</th>

                    </tr>
                    <tr class="d-flex table-secondary">
                        <th class="col-10" scope="row">GRUPO: {{item.nombre}}</th>
                        <!-- <th class="col-1 d-print-none">
                            ({{item.ped_stock }} - {{item.ent_stock}})
                        </th> -->
                        <th class="col-2">
                            Total :{{item.cantidad}}
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr class="d-flex" *ngFor="let i of item.lst">
                        <td class="col-10" scope="row">{{i.nombre | uppercase}} - {{((i.descripcion)?i.descripcion : 'Sin desc') | lowercase }}</td>
                        <td class="col-2" colspan="2">{{i.stock}}</td>

                        <!-- <td class="col-2">20</td> -->
                    </tr>

                </tbody>
            </table>

        </ng-container>

    </div>
</div>
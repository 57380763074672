import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { Mercaderia } from 'src/app/model/index.model';
import { MercaderiaService } from 'src/app/service/index.service';

@Component({
  selector: 'app-fil-mercaderia',
  templateUrl: './fil-mercaderia.component.html',
  styleUrls: ['./fil-mercaderia.component.css']
})
export class FilMercaderiaComponent implements OnInit {
  @ViewChild("filtro", { static: false }) filtro: ElementRef;

  @Output()
  filter: EventEmitter<Mercaderia[]> = new EventEmitter<Mercaderia[]>();

  @Input()
  foco(b: Boolean) {
    if (b) {
      this.search = "";
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.filtro.nativeElement.select();
        this.filtro.nativeElement.focus();
      }, 100);


    }
  }

  focusable() {
    setTimeout(() => {
      this.filtro.nativeElement.focus();
    }, 600);
  }


  proccess: Boolean;
  public search: String;
  public oldSearch: String;

  /* Searcheable table Filter */
  public limit: Number;
  public page: Number;
  public nextPage: Number;
  public prevPage: Number;
  public lastPage: Number;
  public count: Number;
  public limits: Number[] = [5, 10, 25, 50, 100];

  setPage(page) {
    this.page = page;
    this.list()
  }

  constructor(private wsdl: MercaderiaService) {
    this.proccess = false;
    this.limit = 5;
    this.page = 1;

  }

  ngOnInit() {
    this.list()
  }

  async list() {
    this.proccess = true;

    if (this.search === undefined) {
      this.search = "";
    }
    let d = this.oldSearch !== this.search;
    if (d) {
      this.limit = 5;
      this.page = 1;
      this.oldSearch = this.search;
    }

    let c = this.search;
    let data = await this.wsdl.getCriteria(c, this.page, this.limit).then();
    console.log("fil mercaderias", data)
    const result = JSON.parse(JSON.stringify(data));

    if (result.code == 200) {
      this.filter.emit(result.data.docs);

      this.page = parseInt(result.data.paginate.page)
      this.lastPage = parseInt(result.data.paginate.lastPage)
      this.nextPage = parseInt(result.data.paginate.nextPage)
      this.prevPage = parseInt(result.data.paginate.prevPage)
      this.count = parseInt(result.data.paginate.count)

    } else if (result.code === 666) {
      // logout app o refresh token

    } else {
      this.filter.emit([]);
      UturuncoUtils.showToas(result.msg, "error");
    }
    this.proccess = false;

  }

}


import { Component, OnInit } from '@angular/core';
import { DetalleCajaService, DetalleVentaService } from 'src/app/service/index.service';
import * as moment from 'moment';

@Component({
  selector: 'app-reporte-ventas-fecha',
  templateUrl: './reporte-ventas-fecha.component.html',
  styleUrls: ['./reporte-ventas-fecha.component.css']
})
export class ReporteVentasFechaComponent implements OnInit {
  fechade: any;
  fechaha: any;
  items: any;
  ganancia: any;

  fecha = new Date();
  constructor(private wsdl: DetalleVentaService) { }

  ngOnInit() {
  }
  parseDate(fecha) {
    if (moment(fecha).isValid()) {
      return moment(fecha);
    }
  }
  async getList() {
    try {
      this.fechade = moment(this.fechade).format("YYYY-MM-DD") + " 00:00:00";
      this.fechaha = moment(this.fechaha).format("YYYY-MM-DD") + " 23:59:00";

      let data = await this.wsdl.getVentasByFecha(this.fechade, this.fechaha).then();
      console.log(data)
      let res = JSON.parse(JSON.stringify(data));
      if (res.code == 200) {
        this.items = res.data;
        this.ganancia = res.ganancia;
      }
    } catch (error) {

    }
  }

  // para exportar a excel
  exportTableToExcel(tableID, filename = '') {
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';

    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    // Specify file name
    filename = filename ? filename + '.xlsx' : 'excel_data.xlsx';

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(['ufeff', tableHTML], {
        type: dataType
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }
}

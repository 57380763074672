import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DetalleVenta, Mercaderia } from 'src/app/model/index.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DetalleVentaService } from 'src/app/service/index.service';
import { NgForm } from '@angular/forms';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';

@Component({
  selector: 'app-frm-detalle-venta',
  templateUrl: './frm-detalle-venta.component.html',
  styleUrls: ['./frm-detalle-venta.component.css']
})
export class FrmDetalleVentaComponent implements OnInit {

  /*
 * Varaiables de control para comportamientos del componente
 */
  @Output()
  finalizado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output()
  cancelado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  /*
   * control de operaciones a realizar
   */
  proccess: Boolean;
  filtro: Number = 0;

  public entity = 'DetalleVentas';
  public item: DetalleVenta;
  private id: Number;

  constructor(private route: ActivatedRoute, private router: Router, private wsdl: DetalleVentaService) {
    this.item = new DetalleVenta();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
  }

  @Input()
  set select(item: DetalleVenta) {
    if (item.id === undefined) {
      this.item = new DetalleVenta();
      this.item.venta.id = item.venta.id;
      this.item.descripcion = "NUEVO SIN USO "
      if (item.mercaderia.grupo.id != 0) {
        this.filtro = item.mercaderia.grupo.id;

      } else {
        this.filtro = 0;
      }
      this.item.cantidad = item.cantidad;
      this.id = 0;
    } else {
      this.item = new DetalleVenta();
      this.item = item;
      this.id = item.id;
    }
  }

  doAction(f: NgForm) {
    /* validar */
    this.item.usuario = JSON.parse(UturuncoUtils.getSession("user"))

    if (f.valid) {
      if (this.id > 0) {
        //editar
        this.doEdit();
      } else {
        this.doCreate();
      }
    } else {
      alert('Validar');
    }
  }

  async doEdit() {
    try {

      this.proccess = true;
      const res = await this.wsdl.doUpdate(this.item, this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        UturuncoUtils.showToas("Se actualizado correctemte", "success");
        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    this.proccess = false;
  }

  async  doCreate() {
    try {

      this.proccess = true;
      const res = await this.wsdl.doInsert(this.item).then();
      console.log(res)
      const result = JSON.parse(JSON.stringify(res));
      if (result.code == 200) {
        // this.item = result.data;
        UturuncoUtils.showToas("Se creo correctemte", "success");
        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
  }

  back() {
    this.router.navigateByUrl("admin/" + this.entity.toLowerCase() + "/lst/");
  }

  itemSel(event: Mercaderia) {
    if (event.id !== undefined) {
      this.item.mercaderia = event;
    }
  }
}



import { Venta } from './venta';
import { Mercaderia } from './mercaderia';
import { Usuario } from './usuario';

export class DetalleVenta {

  id: Number;
  venta: Venta;
  mercaderia: Mercaderia;
  usuario: Usuario;
  cantidad: Number;
  precio: Number;
  ganancia: Number;
  descripcion: String;

  constructor() {
    this.venta = new Venta();
    this.mercaderia = new Mercaderia();
    this.usuario = new Usuario();
  }

}
import { Usuario } from './usuario';

export class Ingreso {
  id: Number;
  nroFactura: String;
  descripcion: any;
  motivo: String;
  fecha: any;
  usuario: Usuario;

  constructor() {
    this.usuario = new Usuario();
    this.descripcion = { proveedor: '', nro_compra: '', monto: 0, estado_mercaderia: '', estado_pago: '', descripcion: '' };
  }


}
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Mercaderia, DetalleIngreso } from 'src/app/model/index.model';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { CbMercaderia2Component } from '../../combo/cb-mercaderia-2/cb-mercaderia-2.component';
import { DetalleIngresoService } from 'src/app/service/index.service';

@Component({
  selector: 'app-detalle-compra',
  templateUrl: './detalle-compra.component.html',
  styleUrls: ['./detalle-compra.component.css']
})
export class DetalleCompraComponent implements OnInit {

  @ViewChild(CbMercaderia2Component) gr: CbMercaderia2Component;

  @ViewChild("cant", { static: false }) cant: ElementRef;
  @ViewChild("pre", { static: false }) prec: ElementRef;
  @ViewChild("pre2", { static: false }) prec2: ElementRef;

  @ViewChild("close", { static: false }) close: ElementRef;



  @Output() compra = new EventEmitter<DetalleIngreso[]>();

  cantidad: Number = 0;
  precio: Number = 0;
  precio2: Number = 0;
  mercaderia: Mercaderia;
  detalle: DetalleIngreso[];
  item: DetalleIngreso;

  constructor(private wsdldi: DetalleIngresoService) {
    this.detalle = [];
  }

  ngOnInit() {
  }

  select(item: DetalleIngreso) {
    this.item = new DetalleIngreso();
    this.item = item;
  }

  @Input()
  set items(event: DetalleIngreso[]) {
    if (event) {
      this.detalle = event;
    }
  }

  itemSel(event: Mercaderia) {

    if (event) {
      this.mercaderia = event;
      this.precio2 = event.precioVen;
      this.precio = event.precioCom;
      this.cant.nativeElement.select()
    } else {
      UturuncoUtils.showToas("no encontro datos", "info")
    }

  }

  pase() {
    this.prec.nativeElement.focus()
  }
  pase2() {
    this.prec2.nativeElement.focus()
  }
  addRow() {

    if (this.mercaderia.id == undefined || this.cantidad <= 0 || this.precio <= 0) {
      UturuncoUtils.showToas("debe seleccionar un mercaderia y agregar una cantidad", "error")
      return;
    }
    let control = this.detalle.find((e) => e.mercaderia.id == this.mercaderia.id);

    if (control == undefined) {
      let det = new DetalleIngreso();
      det.cantidad = this.cantidad;
      det.mercaderia = this.mercaderia;
      det.precio = this.precio;
      det.precio2 = this.precio2;
      det.usuario = JSON.parse(UturuncoUtils.getSession("user"));
      this.detalle.unshift(det);
      this.compra.emit(this.detalle)
      this.mercaderia = new Mercaderia();
      this.cantidad = 1;
      this.gr.foco(true);

    } else {
      UturuncoUtils.showToas("Este elemento ya existe en tu lista", "info");
    }

  }
  async remove(det) {
    if (det.id > 0) {
      await this.wsdldi.doDelete(det.id).then();
    }
    this.detalle = this.detalle.filter(e => e.mercaderia.id != det.mercaderia.id);
    this.compra.emit(this.detalle)

  }

  setResult(event) {
    this.close.nativeElement.click();
  }

}

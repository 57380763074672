<br>
<div class="row">
    <div class="col-md-12">
        <fieldset>
            <b>Detalle de la venta</b>
            <hr>
        </fieldset>

    </div>

    <div class="col-md-3">
        <div class="form-group">
            <label for="">Cantidad ingresada</label>
            <input #cant type="number" min="1" max="99999" required="false" name="cantidad" id="cantidad" [(ngModel)]="cantidad" class="form-control" placeholder="" aria-describedby="helpId" />
            <small id="helpId" class="text-muted">Ingrese cantidad que ingresa</small>
        </div>
    </div>

    <div class="col-md-4">
        <app-cb-mercaderia-2 [require]="false" [dibujar]="mercaderia" (select)="itemSel($event)">
        </app-cb-mercaderia-2>
    </div>


    <div class="col-md-3">
        <div class="form-group">
            <label for="">Precio</label>
            <input #pre disabled type="number" step="any" min="1" max="99999" required="false" name="precio" id="precio" [(ngModel)]="precio" class="form-control" placeholder="" aria-describedby="helpId" />
            <small id="helpId" class="text-muted">Ingrese precio de compra</small>
        </div>
    </div>

    <div class="col-md-2">
        <div class="form-group">
            <label for="">&nbsp;</label>
            <button (click)="addRow()" type="button" name="agr" id="agr" class="btn btn-primary btn-md btn-block">
                <i class="fas fa-plus"></i>
            </button>
            <small id="helpId" class="text-muted">Agregar a la lista</small>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <table class="table table-striped table-inverse">
            <thead class="thead-inverse">
                <tr class="d-flex">
                    <th class="col-2">cantidad</th>
                    <th class="col-2">precio</th>
                    <th class="col-7">mercaderia</th>
                    <th class="col-1">Quitar</th>
                </tr>
            </thead>
            <tbody>
                <tr class="d-flex" *ngFor="let det of detalle">
                    <td class="col-2" scope="row">
                        {{det.cantidad}}
                    </td>
                    <td class="col-2" scope="row">
                        {{det.precio}}
                    </td>
                    <td class="col-7">[ {{det.mercaderia?.stock}} - {{det.mercaderia?.grupo?.nombre}} ] {{det.mercaderia?.nombre}}
                    </td>
                    <td class="col-1">
                        <button (click)="remove(det)" type="button" class="btn btn-danger btn-sm">
                            <i class="fas fa-trash    "></i>
                        </button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</div>
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Categoria } from 'src/app/model/index.model';
import { CategoriaService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';

@Component({
  selector: 'app-fil-categoria',
  templateUrl: './fil-categoria.component.html',
  styleUrls: ['./fil-categoria.component.scss']
})
export class FilCategoriaComponent implements OnInit {

  @Output()
  filter: EventEmitter<Categoria[]> = new EventEmitter<Categoria[]>();

  proccess: Boolean;
  public search: String;
  public oldSearch: String;

  /* Searcheable table Filter */
  public limit: Number;
  public page: Number;
  public nextPage: Number;
  public prevPage: Number;
  public lastPage: Number;
  public count: Number;
  public limits: Number[] = [5, 10, 25, 50, 100];

  setPage(page) {
    this.page = page;
    this.list()
  }

  constructor(private wsdl: CategoriaService) {
    this.proccess = false;
    this.limit = 5;
    this.page = 1;

  }

  ngOnInit() {
    this.list()
  }

  async list() {
    this.proccess = true;

    if (this.search === undefined) {
      this.search = "";
    }
    let d = this.oldSearch !== this.search;
    if (d) {
      this.limit = 5;
      this.page = 1;
      this.oldSearch = this.search;
    }

    let c = this.search;
    let data = await this.wsdl.getCriteria(c, this.page, this.limit).then();
    const result = JSON.parse(JSON.stringify(data));

    if (result.code == 200) {
      this.filter.emit(result.data.docs);

      this.page = parseInt(result.data.paginate.page)
      this.lastPage = parseInt(result.data.paginate.lastPage)
      this.nextPage = parseInt(result.data.paginate.nextPage)
      this.prevPage = parseInt(result.data.paginate.prevPage)
      this.count = parseInt(result.data.paginate.count)

    } else if (result.code === 666) {
      // logout app o refresh token

    } else {
      this.filter.emit([]);
      UturuncoUtils.showToas(result.msg, "error");
    }
    this.proccess = false;

  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user.component';

const routes: Routes = [
  {
    path: "user", component: UserComponent, children:
      [
        {
          path: "reporte", children: [
            // { path: "stock", component: ReporteStockComponent, pathMatch: 'full' },
            // { path: "entrega", component: ReporteEntregaComponent, pathMatch: 'full' },
          ]
        },
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }

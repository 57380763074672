<div class="row">
    <div class="col-md-4 text-center">
        <img class="logo rounded mx-auto d-block" src="./assets/img/logo.png" alt="">
        <span style="left: 114.752px; top: 105.967px; font-size: 12px;font-weight: bold; font-family: sans-serif; transform: scaleX(1.01324);">Uturunco
            Studio</span>
        <br>
        <span style="left: 141.147px; top: 119.605px; font-size: 10.72px; font-family: sans-serif; transform: scaleX(0.996166);">Empresa
            independiente
            de desarrollo software</span>
    </div>
    <div class="col-md-4 text-center">
        <h4>Reporte de cajas</h4>
        <h6>App CONTROL STOCK</h6>

    </div>
    <div class="col-md-4">
    </div>
</div>

<div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-6 text-center">
        <label>
            Resistencia, {{fecha | date:"dd-MM-yyyy"}}
        </label>
    </div>
    <!-- <div class="col-md-12 text-center">
        <h4>Listado de Stock</h4>
    </div> -->
    <div class="col-md-12">
        <div class="row">
            <!-- <div class="col-md-12">
                <button type="button" (click)="exportTableToExcel('tblData','stock')" class="btn btn-outline-primary">Generar Excel</button>
            </div> -->
            <div class="col-md-12"></div>
        </div>
        <app-fil-caja (filter)="doFound($event)">
        </app-fil-caja>

        <table id="tblData" class="table table-inverse">
            <thead class="thead-inverse">
                <tr>
                    <th>Fecha apertura</th>
                    <th>Gastos | egresos </th>
                    <th>Ventas | ingresos </th>
                    <th>Saldo</th>
                    <th>Corte Caja</th>
                    <th>Detalle</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items" [ngClass]="{'table-success': item.isOpen}">
                    <td scope="row">{{ item.created_at | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                    <td>{{ item.egresos}}</td>
                    <td>{{ item.ingresos}}</td>
                    <td>{{ item.saldo}}</td>
                    <td *ngIf="!item.isOpen">{{ item.importe | currency:'$ '}} a hs {{ item.updated_at | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                    <td *ngIf="item.isOpen">continua abierta la caja</td>
                    <td>
                        <!-- Button trigger modal -->
                        <button *ngIf="!item.isOpen" (click)="select(item)" type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#modelId">
                            <i class="fas fa-eye"></i>
                        </button>


                    </td>
                </tr>

            </tbody>
        </table>

    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Detalle de caja</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <app-detalle-caja-2 [idCaja]="item?.id"></app-detalle-caja-2>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar </button>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { UturuncoUtils } from '../../utils/uturuncoUtils';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DetalleVentaService {

    other_header;
    api;

    constructor(private http: HttpClient) {
        this.api = environment.wsdlUrl + "detalleVenta";
    }

    getList(page, limit) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .get(this.api + "?page=" + page + "&limit=" + limit, { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    getCriteria(criteria, page, limit) {
        this.other_header = UturuncoUtils.getHeader();
        return this.http
            .get(this.api + "/find/" + criteria + "?page=" + page + "&limit=" + limit, { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    getCliente() {
        this.other_header = UturuncoUtils.getHeader();
        return this.http
            .get(this.api + "/factory/cliente", { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    doFind(id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .get(this.api + "/" + id, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    doInsert(evento) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .post(this.api + "/", evento, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }


    doUpdate(evento, id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .put(this.api + "/" + id, evento, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    doDelete(id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .delete(this.api + "/" + id, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    getByCliente(criteria) {
        this.other_header = UturuncoUtils.getHeader();
        return this.http
            .get(this.api + "/factory/cliente/" + criteria, { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    findByUser(user, fe1, fe2) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .post(this.api + "/factory/getVentas/", { query: { usuario: user, fechade: fe1, fechaha: fe2 } }, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    getVentasByFecha(de, hasta) {

        this.other_header = UturuncoUtils.getHeader();

        let body = {
            "query":
                { "fechade": de, "fechaha": hasta },
        };
        console.log(body)
        return this.http
            .post(this.api + "/factory/getVentasbyFecha/", body, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }
    getVentasByCategoria(de, hasta, descripcion) {

        this.other_header = UturuncoUtils.getHeader();

        let body = {
            "query":
                { "fechade": de, "fechaha": hasta, "descripcion": descripcion },
        };

        return this.http
            .post(this.api + "/factory/getVentasbyCategoria/", body, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }
    getVentasByProducto(de, hasta, descripcion) {

        this.other_header = UturuncoUtils.getHeader();

        let body = {
            "query":
                { "fechade": de, "fechaha": hasta, "descripcion": descripcion },
        };
        console.log(body)
        return this.http
            .post(this.api + "/factory/getVentasbyProducto/", body, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

}

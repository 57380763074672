import { Injectable } from '@angular/core';
import { UturuncoUtils } from '../../utils/uturuncoUtils';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IngresoService {
    other_header;
    api;

    constructor(private http: HttpClient) {
        this.api = environment.wsdlUrl + "ingreso";
    }

    getList(page, limit) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .get(this.api + "?page=" + page + "&limit=" + limit, { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    getCriteria(criteria, page, limit) {
        this.other_header = UturuncoUtils.getHeader();
        return this.http
            .post(this.api + "/find/?page=" + page + "&limit=" + limit, { query: criteria }, { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    doFind(id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .get(this.api + "/" + id, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    doInsert(evento) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .post(this.api + "/", evento, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }


    doUpdate(evento, id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .put(this.api + "/" + id, evento, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    doDelete(id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .delete(this.api + "/" + id, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

}

<div class="card">
    <div class="card-header">
        Listado de {{entity | uppercase }}
    </div>
    <div class="card-body">

        <app-fil-usuario (filter)="doFound($event)">
        </app-fil-usuario>

        <div class="row table-responsive">
            <table class="table table-striped table-inverse">
                <thead class="thead-inverse">
                    <tr>
                        <!-- <th>id</th> -->
                        <th>Nombre</th>
                        <th>acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items">
                        <!-- <td scope="row">{{item.id}} </td> -->
                        <td>{{item.usuario}}</td>
                        <td>
                            <button type="button" (click)="linkear(item.id)" class="btn btn-info btn-sm">
                                <i class="fas fa-edit    "></i>
                            </button> &nbsp;
                            <button type="button" (click)="preDelete(item)" class="btn btn-danger btn-sm">
                                <i class="fas fa-trash    "></i>
                            </button> &nbsp;
                            <button type="button" (click)="change(item)" class="btn btn-danger btn-sm">
                                <i class="fas fa-user-secret"></i>
                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer text-muted">
        <div class="btn-group" role="group" aria-label="">
            <a (click)="linkear(0)" class="btn btn-dark">Agregar</a>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <form #f="ngForm" (ngSubmit)="doAction(f)" ngNativeValidate class="was-validated">
            <fieldset>
                <h3>Formulario de alta | modificación {{ entity }}</h3>
            </fieldset>
            <div class="form-group">
                <label for="">Cantiad</label>
                <input type="text" required="true" min="1" name="mercaderia" id="mercaderia" [(ngModel)]="item.cantidad" class="form-control" placeholder="" aria-describedby="helpId" />
                <small id="helpId" class="text-muted">Ingrese cantidad</small>
            </div>
            <div class="form-group">
                <label for="">Precio</label>
                <input type="text" required="true" min="1" name="precio" id="precio" [(ngModel)]="item.precio" class="form-control" placeholder="" aria-describedby="helpId" />
                <small id="helpId" class="text-muted">Ingrese precio</small>
            </div>
            <app-cb-mercaderia [require]="true" [dibujar]="item.mercaderia" (select)="itemSel($event)">
            </app-cb-mercaderia>
            <button type="submit" name="btnA" id="btnA" class="btn btn-primary btn-lg btn-block">
                <i class="fas fa-check"></i>
                aceptar
            </button>
        </form>

    </div>
    &nbsp;

</div>
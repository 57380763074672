<br>
<div class="row">
    <div class="col-md-12">
        <fieldset>
            <b>Detalle de la compra</b>
            <hr>
        </fieldset>

    </div>
    <div class="col-md-4">
        <app-cb-mercaderia-2 [require]="false" [dibujar]="mercaderia" (select)="itemSel($event)">
        </app-cb-mercaderia-2>
    </div>

    <div class="col-md-2">
        <div class="form-group">
            <label for="">Cantidad</label>
            <input #cant type="number" (keydown.enter)="pase2()" min="1" max="99999" required="false" name="cantidad" id="cantidad" [(ngModel)]="cantidad" class="form-control" placeholder="" aria-describedby="helpId" />
            <small id="helpId" class="text-muted">Ingrese cantidad que ingresa</small>
        </div>

    </div>
    <div class="col-md-2">
        <div class="form-group">
            <label for="">Precio Venta</label>
            <input #pre2 type="number" step="any" (keydown.enter)="pase()" min="1" max="99999" required="false" name="precio2" id="precio2" [(ngModel)]="precio2" class="form-control" placeholder="" aria-describedby="helpId" />
            <small id="helpId" class="text-muted">Ingrese precio de venta</small>
        </div>

    </div>

    <div class="col-md-2">
        <div class="form-group">
            <label for="">Precio Compra</label>
            <input #pre type="number" (keydown.enter)="addRow()" step="any" min="1" max="99999" required="false" name="precio" id="precio" [(ngModel)]="precio" class="form-control" placeholder="" aria-describedby="helpId" />
            <small id="helpId" class="text-muted">Ingrese precio de compra</small>
        </div>
    </div>
    <div class="col-md-1">
        <div class="form-group">
            <label for="">Stock</label>
            <input disabled type="text" readonly name="mercaderia" id="mercaderia" [value]="mercaderia?.stock" class="form-control" placeholder="" aria-describedby="helpId" />
            <small id="helpId" class="text-muted">Stock</small>
        </div>

    </div>

    <div class="col-md-1">
        <div class="form-group">
            <label for="">&nbsp;</label>
            <button (click)="addRow()" type="button" name="agr" id="agr" class="btn btn-primary btn-md btn-block">
                <i class="fas fa-plus"></i>
            </button>
            <small id="helpId" class="text-muted">Agregar a la lista</small>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <table class="table table-striped table-inverse">
            <thead class="thead-inverse">
                <tr class="d-flex">
                    <th class="col-1">cant</th>
                    <th class="col-2">precio</th>
                    <th class="col-7">mercaderia</th>
                    <th class="col-2">acción</th>
                </tr>
            </thead>
            <tbody>
                <tr class="d-flex" *ngFor="let det of detalle">
                    <td class="col-1" scope="row">
                        {{det.cantidad}}
                    </td>
                    <td class="col-2" scope="row">
                        {{det.precio}}
                    </td>
                    <td class="col-7">[{{det.mercaderia?.grupo?.nombre}}] {{det.mercaderia?.nombre}}</td>
                    <td class="col-2">
                        <ng-container *ngIf="det.id;then ed"></ng-container>

                        <ng-template #ed>
                            <button type="button" data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#modalFrmEntrega" (click)="select(det)" class="btn btn-info btn-sm">
                                <i class="fas fa-edit"></i>
                            </button> &nbsp;
                        </ng-template>

                        <button (click)="remove(det)" type="button" class="btn btn-danger btn-sm">
                            <i class="fas fa-trash    "></i>
                        </button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="modalFrmEntrega" tabindex="-1" alimento="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-md" alimento="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Editar detalle compra</h5>
                <button #close type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-frm-detalle-ingreso [select]="item" (finalizado)="setResult($event)" (cancelado)="setResult($event)">
                </app-frm-detalle-ingreso>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>
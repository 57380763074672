<div class="row">
    <div class="col-md-4 text-center">
        <img class="logo rounded mx-auto d-block" src="./assets/img/logo.png" alt="">
        <span style="left: 114.752px; top: 105.967px; font-size: 12px;font-weight: bold; font-family: sans-serif; transform: scaleX(1.01324);">Uturunco
            Studio</span>
        <br>
        <span style="left: 141.147px; top: 119.605px; font-size: 10.72px; font-family: sans-serif; transform: scaleX(0.996166);">Empresa
            independiente
            de desarrollo software</span>
    </div>
    <div class="col-md-4 text-center">
        <h4>Reporte de ventas por usuario</h4>
        <h6>App CONTROL STOCK</h6>

    </div>
    <div class="col-md-4">
    </div>
</div>

<div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-6 text-center">
        <label>
            Resistencia, {{fecha | date:"dd-MM-yyyy"}}
        </label>
    </div>
    <div class="col-md-12">
        <button type="button" (click)="exportTableToExcel('tblData','ventas')" class="btn btn-outline-primary">Generar
            Excel</button>
    </div>

    <div class="col-md-12">

        <app-fil-detalle-entrega-2 (filter)="doFound($event)">
        </app-fil-detalle-entrega-2>

        <div class="row table-responsive">
            <table id="tblData" class="table table-striped table-inverse">
                <thead class="thead-inverse">
                    <tr>
                        <!-- <th>id</th> -->
                        <th>Venta</th>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                        <th>Ganancia</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items">
                        <td>{{item.venta?.id}} </td>
                        <td>{{item.venta?.created_at | date :'dd-MM-yyyy'}} </td>
                        <td>{{item.venta?.created_at | date :'HH:mm:ss'}} </td>
                        <td>{{item.mercaderia?.grupo?.nombre}} {{item.mercaderia?.nombre}}</td>
                        <td>{{item.cantidad}}</td>
                        <td>{{item.cantidad * item.precio}}</td>
                        <td>{{item.cantidad * item.ganancia}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
import { Caja } from './caja';

export class DetalleCaja {
  id: Number;
  caja: Caja;
  tipoMovimiento: String;
  descripcion: String;
  importe: Number;
  constructor() {
    this.caja = new Caja();

  }

}
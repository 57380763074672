<div class="card">
    <div class="card-header">
        Listado de {{entity | uppercase }}
    </div>
    <div class="card-body">

        <app-fil-detalle-entrega [id]="idP" (filter)="doFound($event)">
        </app-fil-detalle-entrega>

        <div class="row table-responsive">
            <table class="table table-striped table-inverse">
                <thead class="thead-inverse">
                    <tr>
                        <!-- <th>id</th> -->
                        <th>fecha</th>
                        <th>Mercaderia</th>
                        <th>Entregados</th>
                        <th>acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items">
                        <!-- <td scope="row">{{item.id}} </td> -->
                        <td>{{item.created_at | date :'dd-MM-yyyy'}} </td>
                        <td>{{item.mercaderia?.grupo?.nombre}} {{item.mercaderia?.nombre}}</td>
                        <td>{{item.cantidad}}</td>
                        <td>
                            <button type="button" data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#modalFrmEntrega" (click)="select(item)" class="btn btn-info btn-sm">
                                <i class="fas fa-edit    "></i> Editar
                            </button> &nbsp;
                            <button type="button" (click)="preDelete(item)" class="btn btn-danger btn-sm">
                                <i class="fas fa-trash    "></i> Eliminar
                            </button>&nbsp;

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer text-muted">
        <div class="btn-group" role="group" aria-label="">
            <button data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#modalFrmEntrega" (click)="preNew()" class="btn btn-success">entregar mercaderias</button>
        </div>
    </div>
</div>

<div class="modal fade" id="modalFrmEntrega" tabindex="-1" alimento="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-md" alimento="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">ABM {{entity }}</h5>
                <button #close type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-frm-detalle-entrega [select]="item" (finalizado)="setResult($event)" (cancelado)="setResultCancel($event)">
                </app-frm-detalle-entrega>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <!-- <fieldset>
                <h3>Formulario de alta | modificación {{ entity }}</h3>
            </fieldset> -->
        <form #f="ngForm" ngNativeValidate class="was-validated">

            <div class="form-group">
                <label for="">Número comprobante</label>
                <input type="text" required="true" name="nrofactura" id="nrofactura" [(ngModel)]="item.nroFactura" class="form-control" placeholder="" aria-describedby="helpId" />
                <small id="helpId" class="text-muted">Ingrese número comprobante</small>
            </div>
            <div class="form-group">
                <label for="">Motivo Ingreso</label>

                <select class="form-control" name="motivo" id="motivo" [(ngModel)]="item.motivo">
                    <option value='compra' selected>Compra</option>
                    <option value='carga inicial'>Carga Inicial</option>

                </select>
                <small id="helpId" class="text-muted">Seleccione motivo</small>
            </div>

            <div class="form-group">
                <label for="">Fecha</label>
                <input name="fecha" oninvalid="setCustomValidity('El campo fecha es requerido')" oninput="setCustomValidity('')" type="date" class="form-control form-control-line" id="fecha" placeholder="dd/mm/aaaa" [value]="item.fecha | date: 'yyyy-MM-dd'" (input)="item.fecha = parseDate($event.target.value)"
                    required />
                <small id="helpId" class="text-muted">Ingrese fecha</small>
            </div>

            <div class="row" *ngIf="item.motivo=='compra'">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Nombre proveedor</label>
                        <input type="text" required="true" name="descripcion.proveedor" id="descripcion.proveedor" [(ngModel)]="item.descripcion.proveedor" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese Nombre de proveedor</small>
                    </div>

                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Nro factura compra</label>
                        <input type="number" min="0" required="true" name="descripcion.nro_compra" id="descripcion.nro_compra" [(ngModel)]="item.descripcion.nro_compra" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese nro orden compra</small>
                    </div>

                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Importe compra</label>
                        <input type="number" step="any" required="true" name="descripcion.monto" id="descripcion.monto" [(ngModel)]="item.descripcion.monto" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese importe compra</small>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Estado mercaderia</label>
                        <select class="form-control" name="estado_mercaderia" id="estado_mercaderia" [(ngModel)]="item.descripcion.estado_mercaderia">
                            <option value='' selected>Sin especificar</option>
                            <option value='completa'>Completa</option>
                            <option value='falta entregar'>Falta entregar</option>
                            <option value='falta programar'>Pendiente</option>
                        </select>
                        <small id="helpId" class="text-muted">Ingrese estado mercadera</small>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Estado pago</label>
                        <select class="form-control" name="estado_pago" id="estado_pago" [(ngModel)]="item.descripcion.estado_pago">
                            <option value='' selected>Sin especificar</option>
                            <option value='pagado'>Pagado</option>
                            <option value='falta pagar'>Falta pagar</option>
                        </select>
                        <small id="helpId" class="text-muted">Ingrese estado del pago</small>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">descripcion</label>
                        <input type="text" required="false" name="descripcion.descripcion" id="descripcion.descripcion" [(ngModel)]="item.descripcion.descripcion" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese descripción</small>
                    </div>
                </div>


            </div>
        </form>
        <app-detalle-compra (compra)="compra($event)" [items]="cargainicial"></app-detalle-compra>
        <form #fr="ngForm" (ngSubmit)="doAction(f)" ngNativeValidate class="was-validated">

            <button  [disabled]="proccess" type="submit" name="btnA" id="btnA" class="btn btn-primary btn-lg btn-block">
                <i class="fas fa-check"></i>
                confirmar compra
            </button>

            <ng-container *ngIf="proccess; then proccesando; else noprocess"></ng-container>
            <ng-template #proccesando>
                <div class="alert alert-info alert-dismissible fade show" role="alert">
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <strong>
                    Aguarde
                    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  </strong>
                </div>

            </ng-template>
            <ng-template #noprocess>

            </ng-template>

        </form>
    </div>
    &nbsp;
    <div class="col-md-12">
        <a [routerLink]="[ '/admin/'+entity+'/lst' ]" class="btn btn-secondary btn-lg btn-block">
            <i class="fas fa-arrow-left    "></i> Volver
        </a>
    </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { Venta } from 'src/app/model/index.model';
import { VentaService } from 'src/app/service/index.service';
import { Router } from '@angular/router';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import Swal from 'sweetalert2';
import { FilVentaUsuarioComponent } from '../../filtro/fil-venta-usuario/fil-venta-usuario.component';

@Component({
  selector: 'app-lst-venta',
  templateUrl: './lst-venta.component.html',
  styleUrls: ['./lst-venta.component.css']
})
export class LstVentaComponent implements OnInit {


  @ViewChild(FilVentaUsuarioComponent, { static: true }) fil: FilVentaUsuarioComponent;
  entity = "Ventas"

  items: Venta[];
  item: Venta;

  proccess: Boolean;

  constructor(private wsdl: VentaService, private router: Router) {
    this.item = new Venta();
  }

  ngOnInit() {
    this.fil.list()

  }

  preNew() {
    this.item = new Venta();
  }

  select(item: Venta) {
    this.item = item;
  }

  cancel() {
    this.item = new Venta();
    this.fil.list();
  }

  async setResultCancel(event: boolean) {
    UturuncoUtils.showToas("Operación cancelada", "info");
  }

  setResult(event: any) {
    this.cancel();
  }

  preDelete(item: Venta) {
    this.item = new Venta();
    this.item = item;

    Swal.fire({
      title: 'Esta Seguro?',
      text: '¡No podrás recuperar este archivo ' + item.cliente + '!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '¡Sí, bórralo!',
      cancelButtonText: 'No, mantenlo',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        this.delete()
      } else if (result.dismiss === Swal.DismissReason.cancel) {

        UturuncoUtils.showToas('Tu archivo esta seguro :)', "warning")

      }
    })
  }

  async delete() {
    try {
      this.proccess = true;
      const res = await this.wsdl.doDelete(this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));
      if (result.code == 200) {
        UturuncoUtils.showToas(result.msg, "success");
        this.cancel();
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    this.proccess = false;
  }

  doFound(event: Venta[]) {
    this.items = event;
  }

  linkear(id?: Number) {
    this.router.navigateByUrl("admin/" + this.entity.toLocaleLowerCase() + "/abm/" + id);
  }

  gestionar(id?: Number) {
    this.router.navigateByUrl("admin/detalleVentas/gst/" + id);

  }

}

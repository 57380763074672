import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Ingreso, DetalleIngreso, Mercaderia } from 'src/app/model/index.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IngresoService, DetalleIngresoService, MercaderiaService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { NgForm } from '@angular/forms';
import * as moment from 'moment'
import { CbMercaderiaComponent } from '../../combo/cb-mercaderia/cb-mercaderia.component';

@Component({
  selector: 'app-frm-ingreso',
  templateUrl: './frm-ingreso.component.html',
  styleUrls: ['./frm-ingreso.component.css']
})
export class FrmIngresoComponent implements OnInit {
  /*
   * Varaiables de control para comportamientos del componente
   */


  @Output()
  finalizado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output()
  cancelado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  /*
   * control de operaciones a realizar
   */
  proccess: Boolean;

  public entity = 'ingresos';
  public item: Ingreso;
  private id: Number;

  detalle: DetalleIngreso[];
  cargainicial: DetalleIngreso[];

  constructor(private route: ActivatedRoute, private router: Router, private wsdl: IngresoService, private wsdlme: MercaderiaService, private wsdldi: DetalleIngresoService) {
    this.item = new Ingreso();
    this.detalle = []
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.findID();
  }
  parseDate(fecha) {
    if (moment(fecha).isValid()) {
      return moment(fecha);
    }
  }
  async findID() {
    try {
      if (this.id > 0) {
        let data = await this.wsdl.doFind(this.id).then();
        let res = JSON.parse(JSON.stringify(data));
        if (res.code == 200) {
          this.item = res.data;
          this.item.descripcion = JSON.parse(this.item.descripcion);
          /* encontrar los detalles para mostrar */
          let detda = await this.wsdldi.getCriteria(this.id, 1, 1000).then();
          let detres = JSON.parse(JSON.stringify(detda));
          if (detres.code == 200) {
            this.detalle = detres.data.docs;
            this.cargainicial = this.detalle;
          } else {
            this.detalle = [];
            this.cargainicial = [];
          }
        }
      }

    } catch (error) {
      UturuncoUtils.showToas("Error inesperado", "error");
    }

  }

  doAction(f: NgForm) {
    /* validar */
    this.item.usuario = JSON.parse(UturuncoUtils.getSession("user"))
    this.item.descripcion = JSON.stringify(this.item.descripcion)
    this.item.fecha = moment(this.item.fecha).format("YYYY-MM-DD")

    if (this.detalle.length <= 0) {
      UturuncoUtils.showToas("Atención debe ingresasr mercaderia para continuar", "info")
      return
    }

    if (f.valid) {
      if (this.id > 0) {
        //editar
        this.doEdit();
      } else {
        this.doCreate();
      }
    } else {
      alert('Validar');
    }
  }

  async doEdit() {
    try {

      this.proccess = true;
      const res = await this.wsdl.doUpdate(this.item, this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        for (let index = 0; index < this.detalle.length; index++) {
          const det = this.detalle[index];
          det.ingreso = this.item;
          if (det.id == undefined) {
            await this.wsdldi.doInsert(det);
            await this.wsdlme.doCoste(det.mercaderia.id, det.precio, det.precio2).then();

          }
        }
        UturuncoUtils.showToas("Se actualizado correctemte", "success");
        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }finally{
      this.proccess = false;

    }
  }

  async doCreate() {
    try {

      this.proccess = true;
      const res = await this.wsdl.doInsert(this.item).then();
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        this.item = result.data;
        for (let index = 0; index < this.detalle.length; index++) {
          const det = this.detalle[index];
          det.ingreso = this.item;
          await this.wsdldi.doInsert(det).then();
          await this.wsdlme.doCoste(det.mercaderia.id, det.precio, det.precio2).then();
        }
        UturuncoUtils.showToas("Se creo correctemte", "success");
        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    finally{
      this.proccess = false;
    }
  }

  back() {
    this.router.navigateByUrl("admin/" + this.entity.toLowerCase() + "/lst/");
  }

  compra(event: DetalleIngreso[]) {
    this.detalle = event;

  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class UsuarioService {

  other_header;
  api;

  constructor(private http: HttpClient) {
    this.api = environment.wsdlUrl + "usuario";
  }

  /* particularidad de la entidad */

  getList(page, limit) {
    this.other_header = UturuncoUtils.getHeader();

    return this.http
      .get(this.api + "?page=" + page + "&limit=" + limit, {
        headers: this.other_header,
      })
      .toPromise()
      .catch((err) => { });
  }

  getListCriteria(criteria, page, limit) {
    this.other_header = UturuncoUtils.getHeader();
    return this.http
      .get(
        this.api + "/find/" + criteria + "?page=" + page + "&limit=" + limit,
        { headers: this.other_header }
      )
      .toPromise()
      .catch((err) => {
        console.log(err);
      });
  }

  doUpdate(evento, id) {
    this.other_header = UturuncoUtils.getHeader();

    return this.http
      .put(this.api + "/" + id, evento, { headers: this.other_header })
      .toPromise()
      .catch((err) => { });
  }

  doDelete(id) {
    this.other_header = UturuncoUtils.getHeader();

    return this.http
      .delete(this.api + "/" + id, { headers: this.other_header })
      .toPromise()
      .catch((err) => { });
  }

  doInsert(evento) {
    this.other_header = UturuncoUtils.getHeader();

    return this.http
      .post(this.api + "/", evento, { headers: this.other_header })
      .toPromise()
      .catch((err) => { });
  }

  doFind(id) {
    this.other_header = UturuncoUtils.getHeader();

    return this.http
      .get(this.api + "/" + id, { headers: this.other_header })
      .toPromise()
      .catch((err) => { });
  }

  doLogin(user) {
    this.other_header = UturuncoUtils.getHeader();

    return this.http
      .post(this.api + "/custom/login", user, { headers: this.other_header })
      .toPromise()
      .catch((err) => { });
  }


  doRestorePass(usuario) {
    this.other_header = UturuncoUtils.getHeader();
    let body = {
      usuario: usuario,

    }
    return this.http
      .post(this.api + "/restore/user", body, this.other_header)
      .toPromise().catch(err => {
        console.log(err)
      });
  }

  doChangePass(id, oldClave, newClave) {
    this.other_header = UturuncoUtils.getHeader();
    let body = {
      id: id,
      oldClave: oldClave,
      newClave: newClave
    }
    return this.http
      .post(this.api + "/change/pass", body, this.other_header)
      .toPromise().catch(err => {
        console.log(err)
      });
  }


  doRefresh() {
    this.other_header = UturuncoUtils.getHeader();
    let body = {
      refresh: UturuncoUtils.getSession("refresh")
    }
    return this.http
      .post(this.api + "/refresh/user", body, this.other_header)
      .toPromise().catch(err => {
        console.log(err)
      });
  }

}
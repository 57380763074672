import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { Rol } from 'src/app/model/index.model';
import { RolService } from 'src/app/service/index.service';

@Component({
  selector: 'app-cb-rol',
  templateUrl: './cb-rol.component.html',
  styleUrls: ['./cb-rol.component.scss']
})
export class CbRolComponent implements OnInit {
  @Input() require;
  @Input() disable;
  @Output() select = new EventEmitter<Rol>()

  items: Rol[];
  item: Rol;

  public entity = "Rol"

  constructor(private wsdl: RolService) { }

  ngOnInit() {
    this.lista();
  }

  onChange(item: Rol) {
    this.select.emit(item);
  }

  @Input()
  set dibujar(item: Rol) {
    if (item != undefined) {
      this.item = item;
    } else {
      this.item = new Rol();
    }
  }

  compareFn(c1: Rol, c2: Rol): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  /*lista de item*/
  async lista() {
    try {

      let data = await this.wsdl.getList(1, 500).then();
      const result = JSON.parse(JSON.stringify(data));

      if (result.code == 200) {
        this.items = result.data.docs;
      } else if (result.code == 666) {
        // logout app o refresh token

      } else {
        this.items = [];
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      // UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
  }

}

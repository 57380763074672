import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NavComponent } from './nav/nav.component';
import { TableroComponent } from './tablero/tablero.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from '../app-routing.module';
import { SidebarComponent } from './sidebar/sidebar.component';
@NgModule({
   declarations: [
      LoginComponent,
      NavComponent,
      TableroComponent,
      SidebarComponent

   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      FormsModule,
   ],
   exports: [
      LoginComponent,
      NavComponent,
      TableroComponent,
      SidebarComponent
   ],
   providers: [],
})
export class SharedModule { }

import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    //   $(function () {
    //     $("#menu-toggle").click(function (e) {
    //       e.preventDefault();
    //       $("#wrapper").toggleClass("toggled");
    //     });

    //     $(window).resize(function (e) {
    //       if ($(window).width() <= 768) {
    //         $("#wrapper").removeClass("toggled");
    //       } else {
    //         $("#wrapper").addClass("toggled");
    //       }
    //     });
    //   });
  }

}

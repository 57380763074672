import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Http, Headers, RequestOptions, ResponseContentType } from "@angular/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RepoteService {

  other_header;
  api;


  constructor(private _http: Http, private http: HttpClient) {
    this.api = environment.wsdlUrl + "reporte/";
  }



  getPdf(params, name) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Accept: "application/pdf"
    });
    let options = new RequestOptions({ headers });
    options.responseType = ResponseContentType.ArrayBuffer;
    return this._http
      .post(
        this.api + name,
        params,
        options
      );
  }
  /* particularidad de la entidad */
  getReporte(params, name) {

    const header = new HttpHeaders({
      "Content-type": "application/pdf",
      "Accept": "application/pdf"
    })
    //     get(url: string, options: { headers?: HttpHeaders | { [header: string]: string | string[]; }; observe ?: "body"; params ?: HttpParams | { [param: string]: string | string[];
    //   }; reportProgress?: boolean; responseType: "arraybuffer"; withCredentials?: boolean;
    // })

    return this.http
      .post(this.api + name, params, { headers: header, responseType: "arraybuffer" })
      .toPromise().catch(err => {
        console.log("Error en el servicio ", err);
        //this.msg.viewToas("Error en el servicio " + JSON.stringify(err), "error");
      });
  }
}
<div class="row">
    <div class="col-md-12">
        <form #f="ngForm" (ngSubmit)="doAction(f)" ngNativeValidate class="was-validated">
            <fieldset>
                <h3>Formulario de alta | modificación {{ entity }}</h3>
            </fieldset>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Código</label>
                        <input #serie (keydown.enter)="changeCodbar()" (blur)="changeCodbar()" type="text" name="serie" id="serie" [(ngModel)]="item.serie" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese código barra</small>
                    </div>

                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Nombre de mercaderia</label>
                        <input disabled="{{blocker}}" type="text" required="true" name="mercaderia" id="mercaderia" [(ngModel)]="item.nombre" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese Nombre de mercaderia</small>
                    </div>
                </div>

                <div class="col-md-4">
                    <app-cb-grupo [require]="true" [disable]="blocker" [dibujar]="item.grupo" (select)="itemSel($event)"></app-cb-grupo>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Precio de compra</label>
                        <input disabled="{{blocker}}" type="number" step="any" required="true" name="precioCom" id="precioCom" [(ngModel)]="item.precioCom" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese precio compra</small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Precio de venta</label>
                        <input disabled="{{blocker}}" type="number" step="any" required="true" name="precioVen" id="precioVen" [(ngModel)]="item.precioVen" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese precio venta</small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Precio de oferta</label>
                        <input disabled="{{blocker}}" type="number" step="any" required="true" name="precioOfe" id="precioOfe" [(ngModel)]="item.precioOfe" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese precio oferta</small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Cantidad de oferta</label>
                        <input disabled="{{blocker}}" type="number" step="any" required="true" name="cantOferta" id="cantOferta" [(ngModel)]="item.cantOferta" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese cantidad a partir que se aplica la oferta</small>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Stock minimo</label>
                        <input disabled="{{blocker}}" type="number" step="any" required="true" name="stockMin" id="stockMin" [(ngModel)]="item.stockMin" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese stock minimo</small>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Descripción</label>
                        <input disabled="{{blocker}}" type="text" name="descripcion" id="descripcion" [(ngModel)]="item.descripcion" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese descripcion</small>
                    </div>
                </div>
            </div>

            <button type="submit" [disabled]="blocker" name="btnA" id="btnA" class="btn btn-primary btn-lg btn-block">
                <i class="fas fa-check"></i>
                aceptar
            </button>
        </form>

    </div>
    &nbsp;
    <div class="col-md-12">
        <a [routerLink]="[ '/admin/'+entity+'/lst' ]" class="btn btn-secondary btn-lg btn-block">
            <i class="fas fa-arrow-left    "></i> Volver
        </a>
    </div>
</div>
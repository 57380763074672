<section class="login-block">
  <div class="container">
    <div class="row">
      <div class="col-md-4 login-sec">
        <h2 class="text-center">Iniciar Sesión en Potra Drinky</h2>
        <form #f="ngForm" class="was-validated login-form">
          <div class="form-group">
            <input
              required
              [disabled]="isUser"
              #usercuit
              type="text"
              minlength="4"
              maxlength="20"
              class="form-control disabled"
              [(ngModel)]="cuit"
              id="username"
              name="username"
              placeholder="Ingrese Usuario"
            />
            <div class="invalid-feedback">
              Ingrese usuario como minimo 4 caracteres
            </div>
          </div>
          <div class="form-group">
            <input
              (keydown.enter)="login2()"
              #password
              [@simpleFadeAnimation]="anim"
              required
              minlength="4"
              maxlength="10"
              type="password"
              class="form-control"
              [(ngModel)]="pass"
              id="password"
              name="password"
              placeholder="Clave"
            />
            <div class="invalid-feedback">
              Ingrese su clave de al menos 4 caracteres
            </div>
          </div>
          <div class="form-group">
            <button
              type="button"
              (click)="login2()"
              class="btn btn-primary btn-lg btn-block"
            >
              Ingresar
            </button>
          </div>
          <!-- <a #closed target="_blank" href="{{download}}">&nbsp;</a> -->
        </form>

        <ng-container
          *ngIf="getRR(); then thenBlock; else elseBlock"
        ></ng-container>
        <ng-template #thenBlock>
          <div class="row">
            <div class="col-12 text-center">
              <i class="fas fa-spinner fa-4x fa-spin"></i>
            </div>
          </div>
        </ng-template>
        <ng-template #elseBlock> </ng-template>

        <div class="copy-text">
          Creado con <i class="fa fa-heart"></i> por
          <a href="https://uturuncostudio.net">Uturunco Studio</a>
        </div>
      </div>
      <div class="col-md-8 banner-sec">
        <div
          id="carouselExampleIndicators"
          class="carousel"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <!-- <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> -->
          </ol>
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <img
                class="d-block img-fluid"
                src="https://static.pexels.com/photos/33972/pexels-photo.jpg"
                alt="First slide"
              />
              <div class="carousel-caption d-none d-md-block">
                <div class="banner-text">
                  <h2>Manos a la obra</h2>
                  <p>Potra Drinky</p>
                </div>
              </div>
            </div>
            <!-- <div class="carousel-item">
                                <img class="d-block img-fluid" src="https://images.pexels.com/photos/7097/people-coffee-tea-meeting.jpg" alt="First slide">
                                <div class="carousel-caption d-none d-md-block">
                                    <div class="banner-text">
                                        <h2>This is Heaven</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img class="d-block img-fluid" src="https://images.pexels.com/photos/872957/pexels-photo-872957.jpeg" alt="First slide">
                                <div class="carousel-caption d-none d-md-block">
                                    <div class="banner-text">
                                        <h2>This is Heaven</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                                    </div>
                                </div>
                            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UturuncoUtils } from '../utils/uturuncoUtils';
import { UsuarioService } from '../service/index.service';

@Injectable({
    providedIn: 'root'
})
export class RefreshGuard implements CanActivate {

    constructor(private _router: Router, private wsdl: UsuarioService) {
    }

    canActivate(): Promise<boolean> | boolean {
        let refres = UturuncoUtils.getSession("refresh");

        if (refres) {
            let token = UturuncoUtils.getToken();

            let expirado = true;
            let payload;

            if (token != undefined || token != null) {
                var encodedStr = atob(token.split(".")[1]);
                payload = JSON.parse(encodedStr);
                expirado = this.expirado(payload.exp);
            } else {
                payload = { exp: 1589670086 }
            }

            if (expirado) {
                return this.verificaRenueva(payload.exp);
            } else {
                return this.verificaRenueva(payload.exp);
            }

        } else {
            this._router.navigate([""]);
            return false;
        }

    }

    verificaRenueva(fechaExp: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let tokenExp = new Date(fechaExp * 1000);
            let ahora = new Date();
            ahora.setTime(ahora.getTime() + 15 * 60 * 1000);

            if (tokenExp.getTime() > ahora.getTime()) {
                resolve(true);
            } else {
                this.wsdl.doRefresh().then(
                    (res: any) => {
                        if (res.code == 200) {
                            UturuncoUtils.setToken(res.token);
                            resolve(true);
                        } else {
                            this._router.navigate([""]);
                            reject(false);
                        }
                    },
                    () => {
                        this._router.navigate([""]);
                        reject(false);
                    }
                );
            }
        });
    }

    expirado(fechaExp: number) {
        let ahora = new Date().getTime() / 1000;

        if (fechaExp < ahora) {
            return true;
        } else {
            return false;
        }
    }


}

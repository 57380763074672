import { Component, OnInit, ViewChild } from '@angular/core';
import { FilMercaderiaComponent } from '../../filtro/fil-mercaderia/fil-mercaderia.component';
import { Mercaderia } from 'src/app/model/index.model';
import { MercaderiaService } from 'src/app/service/index.service';
import { Router } from '@angular/router';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lst-mercaderia',
  templateUrl: './lst-mercaderia.component.html',
  styleUrls: ['./lst-mercaderia.component.css']
})
export class LstMercaderiaComponent implements OnInit {

  @ViewChild(FilMercaderiaComponent, { static: true }) fil: FilMercaderiaComponent;
  entity = "Mercaderias"

  items: Mercaderia[];
  item: Mercaderia;

  proccess: Boolean;

  constructor(private wsdl: MercaderiaService, private router: Router) {
    this.item = new Mercaderia();
  }

  ngOnInit() {

  }

  preNew() {
    this.item = new Mercaderia();
  }

  select(item: Mercaderia) {
    this.item = item;
  }

  cancel() {
    this.item = new Mercaderia();
    this.fil.list();
  }

  async setResultCancel(event: boolean) {
    UturuncoUtils.showToas("Operación cancelada", "info");
  }

  setResult(event: any) {
    this.cancel();
  }

  preDelete(item: Mercaderia) {
    this.item = new Mercaderia();
    this.item = item;

    Swal.fire({
      title: 'Esta Seguro?',
      text: '¡No podrás recuperar este archivo ' + item.nombre + '!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '¡Sí, bórralo!',
      cancelButtonText: 'No, mantenlo',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        this.delete()
      } else if (result.dismiss === Swal.DismissReason.cancel) {

        UturuncoUtils.showToas('Tu archivo esta seguro :)', "warning")

      }
    })
  }

  async delete() {
    try {
      this.proccess = true;
      const res = await this.wsdl.doDelete(this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));
      if (result.code == 200) {
        UturuncoUtils.showToas(result.msg, "success");
        this.cancel();
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    this.proccess = false;
  }

  doFound(event: Mercaderia[]) {
    this.items = event;
  }

  linkear(id?: Number) {
    this.router.navigateByUrl("admin/" + this.entity.toLocaleLowerCase() + "/abm/" + id);
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DetalleVenta } from 'src/app/model/index.model';
import { DetalleVentaService, UsuarioService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import * as moment from 'moment';

@Component({
  selector: 'app-fil-detalle-entrega-2',
  templateUrl: './fil-detalle-entrega-2.component.html',
  styleUrls: ['./fil-detalle-entrega-2.component.css']
})
export class FilDetalleVenta2Component implements OnInit {



  @Output()
  filter: EventEmitter<DetalleVenta[]> = new EventEmitter<DetalleVenta[]>();

  proccess: Boolean;
  public search: String;
  public oldSearch: String;

  /* Searcheable table Filter */
  public limit: Number;
  public page: Number;
  public nextPage: Number;
  public prevPage: Number;
  public lastPage: Number;
  public count: Number;
  public limits: Number[] = [5, 10, 25, 50, 100];

  usuario;
  fecha: any = new Date();
  fechaha: any = new Date();
  useFecha: Boolean = false;


  parseDate(fecha) {
    if (moment(fecha).isValid()) {
      return moment(fecha);
    }
  }

  setPage(page) {
    this.page = page;
    this.list()
  }

  constructor(private wsdl: DetalleVentaService, private wsdlu: UsuarioService) {
    this.proccess = false;
    this.limit = 5;
    this.page = 1;

  }

  ngOnInit() {
    this.listUsu()
    this.list()
  }

  async list() {
    this.proccess = true;


    if (this.search === undefined) {
      this.search = "";
    }
    let d = this.oldSearch !== this.search;
    if (d) {
      this.limit = 5;
      this.page = 1;
      this.oldSearch = this.search;
    }

    let c = this.search;

    this.fecha = moment(this.fecha).format("YYYY-MM-DD") + " 00:00:00";
    this.fechaha = moment(this.fechaha).format("YYYY-MM-DD") + " 23:59:00";

    let data
    if (this.useFecha) {
      data = await this.wsdl.findByUser(c, this.fecha, this.fechaha).then();
    } else {
      data = await this.wsdl.getByCliente(c).then();
    }


    const result = JSON.parse(JSON.stringify(data));

    if (result.code == 200) {
      this.filter.emit(result.data);

      // this.page = parseInt(result.data.paginate.page)
      // this.lastPage = parseInt(result.data.paginate.lastPage)
      // this.nextPage = parseInt(result.data.paginate.nextPage)
      // this.prevPage = parseInt(result.data.paginate.prevPage)
      // this.count = parseInt(result.data.paginate.count)

    } else if (result.code === 666) {
      // logout app o refresh token

    } else {
      this.filter.emit([]);
      UturuncoUtils.showToas(result.data, "error");
    }
    this.proccess = false;

  }

  async listUsu() {
    this.proccess = true;

    let data = await this.wsdlu.getList(1, 100).then();

    console.log("uisuario", data)
    const result = JSON.parse(JSON.stringify(data));

    if (result.code == 200) {
      this.usuario = result.data.docs;


    } else if (result.code === 666) {
      // logout app o refresh token

    } else {
      this.usuario = [];
      UturuncoUtils.showToas(result.msg, "error");
    }
    this.proccess = false;

  }


  getRR() {
    return this.proccess;
  }

}

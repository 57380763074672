import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  user: any;
  constructor(private route: Router) {
    this.user = JSON.parse(UturuncoUtils.getSession("user"))
  }
  getuser() {
    this.user = JSON.parse(UturuncoUtils.getSession("user"))
    return this.user;
  }
  ngOnInit() {
  }

  cerrar() {
    UturuncoUtils.clearSession();
    this.route.navigate([""]);
  }

}

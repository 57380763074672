<app-nav></app-nav>

<div class="container-fluid">
    <div class="row">
        <app-sidebar></app-sidebar>

        <main role="main " class="col-md-9 ml-sm-auto col-lg-10 px-4 bg-light">
            <div class="card p-2">
                <div class="card-title">
                    <h4 class="card-title">Panel de administrador</h4>
                </div>
                <div class="card-body">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </main>
    </div>
</div>
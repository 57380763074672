<div class="card">
    <div class="card-header">
        Listado de detalle caja saldo de la misma <b>{{caja?.saldo |  currency:'$' }} </b>
    </div>
    <div class="card-body">

        <app-fil-detalle-caja (filter)="doFound($event)">
        </app-fil-detalle-caja>

        <div class="row table-responsive">
            <table class="table table-striped table-inverse">
                <thead class="thead-inverse">
                    <tr>
                        <!-- <th>id</th> -->
                        <th>Tipo Movimiento</th>
                        <th>Descripción</th>
                        <th>Importe</th>
                        <th *ngIf="caja.isOpen">acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items" [ngClass]="{'table-danger': item.tipoMovimiento=='e'}">
                        <!-- <td scope="row">{{item.id}} </td> -->
                        <td>{{item.tipoMovimiento=='i'?'Ingreso':'Egreso'}}</td>
                        <td>{{item.descripcion}}</td>
                        <td>{{item.importe}}</td>
                        <td *ngIf="caja.isOpen">
                            <button type="button" (click)="linkear(item.id)" class="btn btn-info btn-sm">
                                <i class="fas fa-edit    "></i>
                            </button> &nbsp;
                            <button type="button" (click)="preDelete(item)" class="btn btn-danger btn-sm">
                                <i class="fas fa-trash    "></i>
                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer text-muted">

        <div class="btn-group" role="group" aria-label="">
            <button [disabled]="!caja.isOpen" (click)="linkear(0)" class="btn btn-warning">Agregar movimiento</button>
        </div>
        &nbsp;
        <div class="btn-group" role="group" aria-label="">
            <button [disabled]="!caja.isOpen" (click)="preCierre()" class="btn btn-danger">Corte de caja</button>
        </div>


    </div>
</div>
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { UturuncoUtils } from "../utils/uturuncoUtils";
import { UsuarioService } from '../service/index.service';

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(private router: Router, private wsdl: UsuarioService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const headers = new HttpHeaders({
      "X-Auth-Token": "" + localStorage.getItem("loginAuth")
    });

    const reqClone = req.clone({
      headers,
    });
    return next.handle(reqClone).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {

          if (event.body.code == 200) {
            let refres = UturuncoUtils.getSession("refresh");

            if (refres) {
              let token = UturuncoUtils.getToken();

              let expirado = true;
              let payload;

              if (token != undefined || token != null) {
                var encodedStr = atob(token.split(".")[1]);
                payload = JSON.parse(encodedStr);
                expirado = this.expirado(payload.exp);
              } else {
                payload = { exp: 1589670086 }
              }

              if (expirado) {
                this.verificaRenueva(payload.exp);
              } else {
                this.verificaRenueva(payload.exp);
              }

            } else {
              this.router.navigate([""]);
              // return false;
            }
          }
          if (event.body.code == 666) {
            UturuncoUtils.clearSession();
            this.router.navigate(["/login"]);
          }

          if (event.body.code == 401) {
            alert(event.body.msg);
            this.router.navigate(["/tablero"]);
          }
        }

        return event;
      }),
      catchError(this.manejarError)
    );
  }

  manejarError(error: HttpErrorResponse) {
    console.error("Sucedió un error");
    console.error("Registrado en el log file");
    console.warn(error);
    return throwError("Error personalizado");
  }

  verificaRenueva(fechaExp: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let tokenExp = new Date(fechaExp * 1000);
      let ahora = new Date();
      ahora.setTime(ahora.getTime() + 15 * 60 * 1000);

      if (tokenExp.getTime() > ahora.getTime()) {
        console.log("NO EXPIRO")
        resolve(true);
      } else {
        this.wsdl.doRefresh().then(
          (res: any) => {
            if (res.code == 200) {
              UturuncoUtils.setToken(res.token);
              resolve(true);
            } else {
              console.log("EXPIRO")
              this.router.navigate([""]);
              reject(false);
            }
          },
          () => {
            this.router.navigate([""]);
            reject(false);
          }
        );
      }
    });
  }

  expirado(fechaExp: number) {
    let ahora = new Date().getTime() / 1000;

    if (fechaExp < ahora) {
      return true;
    } else {
      return false;
    }
  }
}

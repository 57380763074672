<div class="row">
    <div class="col-md-12">
        <form #f="ngForm" (ngSubmit)="doAction(f)" ngNativeValidate class="was-validated">
            <fieldset>
                <h3>Formulario de alta | modificación {{ entity }}</h3>
            </fieldset>
            <div class="form-group">
                <label for="">Nombre de usuario</label>
                <input type="text" required="true" name="nombre" id="nombre" [(ngModel)]="item.nombre" class="form-control" placeholder="" aria-describedby="helpId" />
                <small id="helpId" class="text-muted">Ingrese Nombre de usuario</small>
            </div>
            <div class="form-group">
                <label for="">Usuario</label>
                <input type="text" required="true" name="usuario" id="usuario" [(ngModel)]="item.usuario" class="form-control" placeholder="" aria-describedby="helpId" />
                <small id="helpId" class="text-muted">Ingrese usuario</small>
            </div>
            <app-cb-rol [disable]="false" [dibujar]="item.rol" (select)="itemSel($event)"></app-cb-rol>
            <div class="form-group">
                <label for="">Correo</label>
                <input type="text" required="true" name="correo" id="correo" [(ngModel)]="item.correo" class="form-control" placeholder="" aria-describedby="helpId" />
                <small id="helpId" class="text-muted">Ingrese correo</small>
            </div>

            <!-- <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Desde</label>
                        <input type="time" required="true" name="habDesd" id="habDesd" [(ngModel)]="item.habDesd" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese habilitado Desde</small>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Hasta</label>
                        <input type="time" required="true" name="habHast" id="habHast" [(ngModel)]="item.habHast" class="form-control" placeholder="" aria-describedby="helpId" />
                        <small id="helpId" class="text-muted">Ingrese habilitado Hasta</small>
                    </div>
                </div>
            </div> -->
            <button type="submit" name="btnA" id="btnA" class="btn btn-primary btn-lg btn-block">
                <i class="fas fa-check"></i>
                aceptar
            </button>
        </form>

    </div>
    &nbsp;
    <div class="col-md-12">
        <a [routerLink]="[ '/admin/'+entity+'/lst' ]" class="btn btn-secondary btn-lg btn-block">
            <i class="fas fa-arrow-left    "></i> Volver
        </a>
    </div>
</div>
import { Injectable } from '@angular/core';
import { UturuncoUtils } from '../../utils/uturuncoUtils';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CajaService {
    other_header;
    api;

    constructor(private http: HttpClient) {
        this.api = environment.wsdlUrl + "caja";
    }

    getList(page, limit) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .get(this.api + "?page=" + page + "&limit=" + limit, { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    getCriteria(criteria, page, limit) {
        this.other_header = UturuncoUtils.getHeader();
        return this.http
            .get(this.api + "/find/" + criteria + "?page=" + page + "&limit=" + limit, { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    doFind(id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .get(this.api + "/" + id, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    doInsert(evento) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .post(this.api + "/", evento, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }


    doUpdate(evento, id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .put(this.api + "/" + id, evento, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    doDelete(id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .delete(this.api + "/" + id, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    isCajaByUserAndFecha(usuario, fe1, fe2) {
        this.other_header = UturuncoUtils.getHeader();
        let body = { query: { usuario: usuario, inicio: fe1, fin: fe2 } }
        return this.http
            .post(this.api + "/isCajaByUserAndFecha/", body, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }


}

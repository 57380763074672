import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Mercaderia } from 'src/app/model/index.model';
import { MercaderiaService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';

@Component({
  selector: 'app-cb-mercaderia',
  templateUrl: './cb-mercaderia.component.html',
  styleUrls: ['./cb-mercaderia.component.css']
})
export class CbMercaderiaComponent implements OnInit {

  @Output() select = new EventEmitter<Mercaderia>();
  @Input() require

  items: Mercaderia[];
  allitems: Mercaderia[];
  item: Mercaderia;

  public entity = "Mercaderia"

  constructor(private wsdl: MercaderiaService) {

  }

  ngOnInit() {
    this.lista();
  }

  onChange(item: Mercaderia) {
    this.select.emit(item);
  }

  @Input()
  set filtro(data) {
    if (data != 0) {
      this.items = this.allitems.filter(e => e.grupo.id == data);
    } else {
      this.items = this.allitems;
    }
  }

  @Input()
  set dibujar(item: Mercaderia) {
    if (item != undefined) {
      this.item = item;
    } else {
      this.item = new Mercaderia();
    }
  }

  compareFn(c1: Mercaderia, c2: Mercaderia): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  /*lista de item*/
  async lista() {
    try {

      let data = await this.wsdl.getList(1, 1000).then();
      const result = JSON.parse(JSON.stringify(data));
      if (result.code == 200) {
        this.items = result.data.docs;
        this.allitems = this.items;
      } else if (result.code == 666) {
        // logout app o refresh token

      } else {
        this.items = [];
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      // UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
  }

}


import { Injectable } from '@angular/core';
import { UturuncoUtils } from '../../utils/uturuncoUtils';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DetalleCajaService {
    other_header;
    api;

    constructor(private http: HttpClient) {
        this.api = environment.wsdlUrl + "detalleCaja";
    }

    getList(page, limit) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .get(this.api + "?page=" + page + "&limit=" + limit, { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    getCriteria(criteria, page, limit) {
        this.other_header = UturuncoUtils.getHeader();
        return this.http
            .get(this.api + "/find/" + criteria + "?page=" + page + "&limit=" + limit, { headers: this.other_header })
            .toPromise()
            .catch(err => { })
    }

    doFind(id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .get(this.api + "/" + id, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    doInsert(evento) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .post(this.api + "/", evento, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }


    doUpdate(evento, id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .put(this.api + "/" + id, evento, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    doDelete(id) {
        this.other_header = UturuncoUtils.getHeader();

        return this.http
            .delete(this.api + "/" + id, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    getPreCierre() {
        this.other_header = UturuncoUtils.getHeader();
        let user = JSON.parse(UturuncoUtils.getSession("user"));
        let body = {
            "query":
                { "usuario": user.id }
        };
        return this.http
            .post(this.api + "/preCierreCaja/", body, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }
    getCierre(importe) {
        this.other_header = UturuncoUtils.getHeader();
        let user = JSON.parse(UturuncoUtils.getSession("user"));
        let body = {
            "query":
                { "usuario": user.id, "importe": importe },

        };
        return this.http
            .post(this.api + "/cierreCaja/", body, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }

    getDetaleCaja(id) {
        this.other_header = UturuncoUtils.getHeader();
        return this.http
            .get(this.api + "/getResumenVentas/" + id, { headers: this.other_header })
            .toPromise()
            .catch(err => { });
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gst-venta',
  templateUrl: './gst-venta.component.html',
  styleUrls: ['./gst-venta.component.css']
})
export class GstVentaComponent implements OnInit {
  public id: Number;
  constructor(private route: ActivatedRoute, ) {
    this.id = this.route.snapshot.params.id;
  }

  ngOnInit() {
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { Categoria } from 'src/app/model/index.model';
import { CategoriaService } from 'src/app/service/index.service';

@Component({
  selector: 'app-cb-categoria',
  templateUrl: './cb-categoria.component.html',
  styleUrls: ['./cb-categoria.component.scss']
})
export class CbCategoriaComponent implements OnInit {

  @Output() select = new EventEmitter<Categoria>()

  items: Categoria[];
  item: Categoria;

  public entity = "Categoria"

  constructor(private wsdl: CategoriaService) { }

  ngOnInit() {
    this.lista();
  }

  onChange(item: Categoria) {
    this.select.emit(item);
  }

  @Input()
  set dibujar(item: Categoria) {
    if (item != undefined) {
      this.item = item;
    } else {
      this.item = new Categoria();
    }
  }

  compareFn(c1: Categoria, c2: Categoria): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  /*lista de item*/
  async lista() {
    try {

      let data = await this.wsdl.getList(1, 500).then();
      const result = JSON.parse(JSON.stringify(data));

      if (result.code == 200) {
        this.items = result.data.docs;
      } else if (result.code == 666) {
        // logout app o refresh token

      } else {
        this.items = [];
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      // UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
  }

}

<div class="card">
    <div class="card-header">
        Gestión de venta
    </div>
    <div class="card-body">
        <app-lst-detalle-venta [idP]="id"></app-lst-detalle-venta>
        <app-lst-detalle-entrega [idP]="id"></app-lst-detalle-entrega>
    </div>
    <div class="card-footer text-muted">
        <div class="btn-group" role="group" aria-label="">

        </div>
    </div>
</div>
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Usuario, Rol } from 'src/app/model/index.model';
import { UsuarioService } from 'src/app/service/index.service';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-frm-usuario',
  templateUrl: './frm-usuario.component.html',
  styleUrls: ['./frm-usuario.component.css'],
})
export class FrmUsuarioComponent implements OnInit {
  /*
   * Varaiables de control para comportamientos del componente
   */
  @Output()
  finalizado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output()
  cancelado: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  /*
   * control de operaciones a realizar
   */
  proccess: Boolean;

  public entity = 'usuarios';
  public item: Usuario;
  private id: Number;

  constructor(private route: ActivatedRoute, private router: Router, private wsdl: UsuarioService) {
    this.item = new Usuario();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.findID();
  }

  async findID() {
    try {
      if (this.id > 0) {
        let data = await this.wsdl.doFind(this.id).then();
        let res = JSON.parse(JSON.stringify(data));
        if (res.code == 200) {
          this.item = res.data;
        }
      }

    } catch (error) {
      UturuncoUtils.showToas("Error inesperado", "error");
    }

  }

  doAction(f: NgForm) {
    /* validar */

    if (f.valid) {
      if (this.id > 0) {
        //editar
        this.doEdit();
      } else {
        this.doCreate();
      }
    } else {
      alert('Validar');
    }
  }

  async doEdit() {
    try {

      this.proccess = true;
      const res = await this.wsdl.doUpdate(this.item, this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        UturuncoUtils.showToas("Se actualizado correctemte", "success");
        this.finalizado.emit(true);
      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    this.proccess = false;
  }

  async  doCreate() {
    try {

      this.proccess = true;

      const res = await this.wsdl.doInsert(this.item).then();
      const result = JSON.parse(JSON.stringify(res));

      if (result.code == 200) {
        // this.item = result.data;
        Swal.fire({
          title: 'La clave ?',
          text: result.data + " para el usuario " + this.item.nombre + '!',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: '¡Sí, entiendo!',
          confirmButtonColor: '#3085d6',
        }).then((result) => {
          UturuncoUtils.showToas("Se creo correctemte", "success");
          this.finalizado.emit(true);
        })

      } else if (result.code == 666) {
        // logout app o refresh token
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
  }

  back() {
    this.router.navigateByUrl("admin/" + this.entity.toLowerCase() + "/lst/");
  }

  itemSel(event: Rol) {
    if (event.id != undefined) {
      this.item.rol = event;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/index.service';

@Component({
  selector: 'app-tablero',
  templateUrl: './tablero.component.html',
  styleUrls: ['./tablero.component.scss']
})
export class TableroComponent implements OnInit {

  totalVenta: Number;
  totalGanancia: Number;
  totalGastos: Number;
  totalVentaM: Number;
  totalGananciaM: Number;
  totalGastosM: Number;

  porVenta: any;
  porGanancia: any;
  porGastos: any;
  constructor(private wsdl: ApiService) { }

  ngOnInit() {
    this.find();
  }

  async find() {
    try {

      let data = await this.wsdl.finTablero().then();
      console.log("reso", data)
      let res = JSON.parse(JSON.stringify(data));

      if (res.code == 200) {
        this.totalVenta = res.data.ventas;
        this.totalGanancia = res.data.ganancias;
        this.totalGastos = res.data.egresos;

        this.totalVentaM = res.data.ventasM;
        this.totalGananciaM = res.data.gananciasM;
        this.totalGastosM = res.data.egresosM;

        this.porVenta = (parseFloat(this.totalVenta + "") * 100) / parseFloat(this.totalVentaM + "");
        this.porGanancia = (parseFloat(this.totalGanancia + "") * 100) / parseFloat(this.totalGananciaM + "");
        this.porGastos = (parseFloat(this.totalGastos + "") * 100) / parseFloat(this.totalGastosM + "");

        this.porVenta = this.porVenta.toFixed(2);
        this.porGanancia = this.porGanancia.toFixed(2);
        this.porGastos = this.porGastos.toFixed(2);

      }

    } catch (error) {

    }

  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/model/index.model';
import Swal from 'sweetalert2';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { UsuarioService } from 'src/app/service/index.service';
import { FilUsuarioComponent } from '../../filtro/fil-usuario/fil-usuario.component';

@Component({
  selector: 'app-lst-usuario',
  templateUrl: './lst-usuario.component.html',
  styleUrls: ['./lst-usuario.component.css']
})
export class LstUsuarioComponent implements OnInit {

  @ViewChild(FilUsuarioComponent, { static: true }) fil: FilUsuarioComponent;
  entity = "Usuarios"

  items: Usuario[];
  item: Usuario;

  proccess: Boolean;

  constructor(private wsdl: UsuarioService, private router: Router) {
    this.item = new Usuario();
  }

  ngOnInit() {

  }

  preNew() {
    this.item = new Usuario();
  }

  select(item: Usuario) {
    this.item = item;
  }

  cancel() {
    this.item = new Usuario();
    this.fil.list();
  }

  async setResultCancel(event: boolean) {
    UturuncoUtils.showToas("Operación cancelada", "info");
  }

  setResult(event: any) {
    this.cancel();
  }

  preDelete(item: Usuario) {
    this.item = new Usuario();
    this.item = item;

    Swal.fire({
      title: 'Esta Seguro?',
      text: '¡No podrás recuperar este archivo ' + item.nombre + '!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '¡Sí, bórralo!',
      cancelButtonText: 'No, mantenlo',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        this.delete()
      } else if (result.dismiss === Swal.DismissReason.cancel) {

        UturuncoUtils.showToas('Tu archivo esta seguro :)', "warning")

      }
    })
  }

  async  change(item) {
    let data = await this.wsdl.doRestorePass(item.usuario).then();
    let res = JSON.parse(JSON.stringify(data))
    if (res.code == 200) {
      Swal.fire({
        title: 'La clave ?',
        text: res.data + " para el usuario " + item.usuario + '!',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: '¡Sí, entiendo!',
        confirmButtonColor: '#3085d6',
      }).then((result) => {
        UturuncoUtils.showToas("Se reestablecio correctemte la clave", "success");
      })

    } else {
      UturuncoUtils.showToas("Ocurrio un error al reestablecer la clave", "error")
    }
  }
  async delete() {
    try {
      this.proccess = true;
      const res = await this.wsdl.doDelete(this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));
      if (result.code == 200) {
        UturuncoUtils.showToas(result.msg, "success");
        this.cancel();
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    this.proccess = false;
  }

  doFound(event: Usuario[]) {
    this.items = event;
  }

  linkear(id?: Number) {
    this.router.navigateByUrl("admin/" + this.entity.toLocaleLowerCase() + "/abm/" + id);
  }

}

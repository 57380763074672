import { Grupo } from './grupo';

export class Mercaderia {
  id: Number;
  nombre: String;
  grupo: Grupo;
  serie: String;
  stockMin: Number;
  precioCom: Number;
  precioVen: Number;
  descripcion: String;
  ing_stock: Number;
  egr_stock: Number;
  precioOfe: Number;
  cantOferta: Number;
  stock: Number;

  constructor() {
    this.grupo = new Grupo();

  }

}
import { Component, OnInit, ViewChild } from '@angular/core';
import { DetalleCaja, Caja } from 'src/app/model/index.model';
import { UturuncoUtils } from 'src/app/utils/uturuncoUtils';
import { DetalleCajaService } from 'src/app/service/index.service';
import Swal from 'sweetalert2';
import { FilDetalleCajaComponent } from '../../filtro/fil-detalle-caja/fil-detalle-caja.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detalle-caja',
  templateUrl: './detalle-caja.component.html',
  styleUrls: ['./detalle-caja.component.css']
})
export class LstDetalleCajaComponent implements OnInit {


  @ViewChild(FilDetalleCajaComponent, { static: true }) fil: FilDetalleCajaComponent;
  entity = "DetalleCajas"

  items: DetalleCaja[];
  item: DetalleCaja;

  caja: Caja;

  proccess: Boolean;

  constructor(private wsdl: DetalleCajaService, private router: Router) {
    this.item = new DetalleCaja();
    this.caja = new Caja();
  }

  ngOnInit() {
    this.cancel();
  }

  preNew() {
    this.item = new DetalleCaja();
  }

  select(item: DetalleCaja) {
    this.item = item;
  }

  cancel() {
    this.item = new DetalleCaja();
    this.fil.list();
  }

  async setResultCancel() {
    UturuncoUtils.showToas("Operación cancelada", "info");
  }

  setResult() {
    this.cancel();
  }

  preDelete(item: DetalleCaja) {
    this.item = new DetalleCaja();
    this.item = item;

    Swal.fire({
      title: 'Esta Seguro?',
      text: '¡No podrás recuperar este archivo ' + item.importe + '!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '¡Sí, bórralo!',
      cancelButtonText: 'No, mantenlo',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        this.delete()
      } else if (result.dismiss === Swal.DismissReason.cancel) {

        UturuncoUtils.showToas('Tu archivo esta seguro :)', "warning")

      }
    })
  }

  async delete() {
    try {
      this.proccess = true;
      const res = await this.wsdl.doDelete(this.item.id).then();
      const result = JSON.parse(JSON.stringify(res));
      if (result.code == 200) {
        UturuncoUtils.showToas(result.msg, "success");
        this.cancel();
      } else {
        UturuncoUtils.showToas(result.msg, "error");
      }
    } catch (error) {
      UturuncoUtils.showToas("Excepción: " + error.message, "error");
    }
    this.proccess = false;
  }

  doFound(event: DetalleCaja[]) {
    this.items = event;
    if (this.items.length > 0) {
      this.caja = this.items[0].caja
    } else {
      this.caja = JSON.parse(UturuncoUtils.getSession("caja"));
    }
  }

  linkear(id?: Number) {
    this.router.navigateByUrl("admin/" + this.entity.toLocaleLowerCase() + "/abm/" + id);
  }

  async preCierre() {
    try {

      let res = await Swal.fire({
        title: 'Ingrese importe de su caja',
        input: 'number',
        inputAttributes: {
          autocapitalize: 'off'
        },
        confirmButtonText: 'aceptar',
      }).then()

      if (res.value) {
        this.closeCaja(res.value)
      }


      // this.proccess = true;

      // let data = await this.wsdl.getPreCierre().then();
      // let res = JSON.parse(JSON.stringify(data));
      // let that = this;

      // if (res.code == 200) {


      //   //  UturuncoUtils.showToas(JSON.stringify(res.data), "info")
      // }

    } catch (error) {

    }
  }

  async closeCaja(importe) {

    try {
      let user = JSON.parse(UturuncoUtils.getSession("user"));

      let data = await this.wsdl.getCierre(importe).then();
      let res = JSON.parse(JSON.stringify(data));
      let that = this;
      if (res.code == 200) {
        Swal.fire(
          'Se cerro la caja con exito!',
          'Gracias por usar el sistema hasta luego ' + user.nombre,
          'success'
        )
        UturuncoUtils.clearSession();
        this.router.navigate([""])
      }

    } catch (error) {

    } finally {

    }

  }


}

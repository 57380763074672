import { Ingreso } from './ingreso';
import { Mercaderia } from './mercaderia';
import { Usuario } from './usuario';

export class DetalleIngreso {
  id: Number;
  ingreso: Ingreso;
  mercaderia: Mercaderia;
  usuario: Usuario;
  precio: Number;
  precio2: Number;
  cantidad: Number;
  constructor() {
    this.ingreso = new Ingreso();
    this.mercaderia = new Mercaderia();
    this.usuario = new Usuario();
  }

}
<div class="card">
    <div class="card-header">
        Listado de {{entity | uppercase }}
    </div>
    <div class="card-body">

        <app-fil-venta-usuario (filter)="doFound($event)">
        </app-fil-venta-usuario>

        <div class="row table-responsive">
            <table class="table table-striped table-inverse">
                <thead class="thead-inverse">
                    <tr>
                        <!-- <th>id</th> -->
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Venta</th>
                        <!-- <th>acción</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items">
                        <!-- <td scope="row">{{item.id}} </td> -->
                        <td>{{item.created_at | date :'dd-MM-yyyy HH:mm:ss'}} </td>
                        <td>{{item.cliente | uppercase}}</td>
                        <td>{{item.descripcion}}</td>

                        <!-- 
                               <td>
                          
                            <button type="button" (click)="linkear(item.id)" class="btn btn-info btn-sm">
                                <i class="fas fa-edit"></i>
                                editar
                            </button> &nbsp;
                           <button type="button" (click)="preDelete(item)" class="btn btn-danger btn-sm">
                                <i class="fas fa-trash"></i>
                                eliminar
                            </button> &nbsp; -->
                        <!-- <button type="button" (click)="gestionar(item.id)" class="btn btn-warning btn-sm">
                                <i class="fas fa-paste    "></i>
                                entregar
                            </button>

                        </td>
 -->

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer text-muted">
        <div class="btn-group" role="group" aria-label="">
            <a (click)="linkear(0)" class="btn btn-dark">Agregar</a>
        </div>
    </div>
</div>
import { Usuario } from './usuario';

export class Venta {
  id: Number;
  cliente: String;
  usuario:Usuario;
  estado: String;
  descripcion: String;
  ped_stock: Number;
  ent_stock: Number;
  constructor() {
    this.usuario= new Usuario()

  }

}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';


@NgModule({
   declarations: [
      UserComponent,

   ],
   imports: [
      HttpClientModule,
      BrowserModule,
      SharedModule,
      UserRoutingModule,
      FormsModule,
   ],
   exports: [

   ],
   providers: [],
   schemas: [
      CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
   ],
})
export class UserModule { }
<a class="label label-primary" data-toggle="collapse" href="#callapseGrupoFil" role="button" aria-expanded="false" aria-controls="callapseGrupoFil">
    Filtrar
</a>
<div class="row" id="callapseGrupoFil">

    <div class="col-md-2">
        <select (change)="list()" class="form-control" name="limit" id="limit" [(ngModel)]="limit">
            <option *ngFor="let c of limits" [ngValue]="c">{{c}}</option>
        </select>

    </div>
    <div class="col-md-5">

        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li [class]="(prevPage>0)?'page-item':'page-item disabled'">
                    <a (click)="setPage(prevPage)" class="page-link" href="javascript:void(0);" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li class="page-item ">
                    <a class="page-link" href="javascript:void(0);">
                        Pagina {{page}} de {{lastPage}} total {{count}} registro
                    </a>
                </li>
                <li [class]="(page===lastPage)?'page-item disabled':'page-item'">
                    <a (click)="setPage(nextPage)" class="page-link" href="javascript:void(0);" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="col-md-5 ">
        <form ngNativeValidate #f="ngForm">
            <div id="custom-search-input">
                <div class="input-group col-md-12 mb-2">
                    <input name="filtro" [(ngModel)]="search" type="text" class="search-query form-control" placeholder="Buscar" />
                    <span class="input-group-btn">
                        <button class="btn btn-danger" (click)="list()" type="submit">
                            <span class=" fas fa-search"></span>
                    </button>
                    </span>
                </div>
            </div>
        </form>
    </div>
</div>